import React, { useEffect, useState } from "react";
import "./css/style.css";

import { Box, IconButton, Typography } from "@mui/material";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import img1 from "../../assets/img/carousel/image_1.jpg";
import img2 from "../../assets/img/carousel/image_2.jpg";
import img3 from "../../assets/img/carousel/image_3.jpg";
import img4 from "../../assets/img/carousel/image_4.jpg";
import { Delete, PlayArrow, PlayCircle } from "@mui/icons-material";

const CarouselLanding = () => {
  var items = [
    {
      name: "Random Name #1",
      description:
        // "We can design your dream home or retrofit your existing house for cost effective performance from Code Minimum to Net Zero.",
        "Get project support with building permit applications, air tightness testing, and verification of energy efficient construction.",
      image: img1,
    },
    {
      name: "Random Name #2",
      description:
        // "We help you with building permit applications, verification of energy efficient construction techniques and air tightness testing.",
        "Plan for occupant comfort and achieve higher performance with advanced modeling and reporting that helps you choose the right specification package.",
      image: img2,
    },
    {
      name: "Random Name #3",
      description:
        // "Building Commissioning services for new and existing buildings, specializing in multifamily construction.",
        "Differentiate your product and gain marketing advantage with third-party green building certification.",
      image: img3,
    },
    {
      name: "Random Name #4",
      description:
        // "Gain marketing advantage by standing out with third-party certification with all of today’s green building programs.",
        "Improve your project’s air tightness and identify potential issues before they become a warranty concern with mid-construction reviews and testing.",
      image: img4,
    },
  ];

  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 30,
    height: 30,
    cursor: "pointer",
  };

  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <>
      <Box>
        <Carousel
          // statusFormatter={(current, total) => `Current slide: ${current} / Total: ${total}`}
          swipeable={false}
          dynamicHeight={false}
          interval={6000}
          transitionTime={2000}
          animationHandler={"fade"}
          showStatus={false}
          autoPlay={true}
          showArrows={false}
          showIndicators={false}
          showThumbs={false}
          infiniteLoop={true}
          renderArrowPrev={(onClickHandler, hasPrev, label) => {
            return (
              hasPrev && (
                <IconButton
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    ...arrowStyles,
                    left: 15,
                  }}
                >
                  {/* PREV */}
                  <PlayCircle sx={{ transform: "scaleX(-1)" }} />
                </IconButton>
              )
            );
          }}
          renderArrowNext={(onClickHandler, hasNext, label) => {
            return (
              hasNext && (
                <IconButton
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    ...arrowStyles,
                    right: 15,
                  }}
                >
                  <PlayCircle />
                </IconButton>
              )
            );
          }}
        >
          {items.map((item) => {
            return (
              <>
                <Box>
                  {/* <Box position={"absolute"} bottom={"0px"} width={"30%"} height={"100%"} sx={{backgroundColor:"#0B7B04"}}> */}
                  {/* <Box position={"absolute"} display={"flex"} bottom={"0px"} width={"50%"} margin={"3rem"} > */}
                  <Box
                    position={"absolute"}
                    display={"flex"}
                    bottom={"10%"}
                    width={{ xs: "100%", sm: "100%", md: "1100px", lg: "1100px" }}
                    margin={"auto"}
                    left={0}
                    right={0}
                    marginLeft={"auto"}
                    marginRight={"auto"}
                  >
                    {/* <Typography variant={hSize} marginBottom={"2rem"} fontFamily="Oswald" textTransform={(upperCase)?"uppercase":""}> */}
                    <Box width={{xs:"100%",sm:"50%",md:"50%"}} margin={{ xs: "2rem", sm: "2rem", md: "2rem", lg: "0px" }}>
                    <Typography
                      align="justify"
                      variant={"h5"}
                      fontFamily="Oswald"
                      color={"white"}
                    >
                      {item.description}
                    </Typography>
                    </Box>
                  </Box>

                  <img
                    src={item.image}
                    style={{
                      zIndex: -1,
                      objectFit: "cover",
                      maxHeight: "350px",
                      position: "relative",
                      filter: "brightness(0.5)",
                    }}
                  />
                  {/* <Typography className="legend">{item.name}</Typography> */}
                  <Box
                    sx={{
                      display: "inherit",
                      bottom: "40px",
                      position: "relative",
                    }}
                  >
                    {/* <Typography>{item.name}</Typography> */}
                  </Box>
                </Box>
              </>
            );
          })}
        </Carousel>
      </Box>
    </>
  );
};

export default CarouselLanding;
