import {
  ArrowDropDown,
  ChevronLeft,
  Menu as MenuIcon,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Link as LinkMui
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";

const MenuDrawer = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <React.Fragment>
      {/* <Drawer sx={{display:{xs:'none', md:'flex'}}} */}
      <Drawer
        sx={{ paddingLeft: "0px !important" }}
        open={openMenu}
        onClose={() => {
          setOpenMenu(false);
        }}
      >
        <Box
          // margin={"1rem"}
          alignItems={"center"}
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"row"}
          alignContent={"center"}
          sx={{ verticalAlign: "middle" }}
          margin={"1rem"}
        >
          <Typography>4 Elements</Typography>
          <IconButton
            onClick={() => {
              setOpenMenu(false);
            }}
          >
            <ChevronLeft />
          </IconButton>
        </Box>
        <List sx={{ paddingTop: "0px" }}>
          <Divider />
          <Link
            // href="/"
            style={{ textDecoration: "none", color: "inherit" }}
            to={"/"}
            onClick={() => {
              setOpenMenu(false);
            }}
          >
            <ListItemButton>
              <ListItemText>
                Home
              </ListItemText>
            </ListItemButton>
          </Link>
          <Divider />
          
          <Link
              // href="/"
              style={{ textDecoration: "none", color: "inherit" }}
              to={"/residential"}
              onClick={() => {
                setOpenMenu(false);
              }}
            >
              <ListItemButton>
                <ListItemText>
                  Services
                </ListItemText>
              </ListItemButton>
            </Link>
          <Divider />
          <Link
            // href="/"
            style={{ textDecoration: "none", color: "inherit" }}
            to={"/team"}
            onClick={() => {
              setOpenMenu(false);
            }}
          >
            <ListItemButton>
              <ListItemText>
                About
              </ListItemText>
            </ListItemButton>
          </Link>
          <Divider />
          <Link
            // href="/"
            style={{ textDecoration: "none", color: "inherit" }}
            target="#"
            to={"https://4egreenhomes.medium.com/"}
          >
            <ListItemButton>
              <ListItemText onClick={() => setOpenMenu(false)}>
                Blog
              </ListItemText>
            </ListItemButton>
          </Link>
          <Divider />
          <Link
            // href="/"
            style={{ textDecoration: "none", color: "inherit" }}
            to={"/contact"}
            onClick={() => {
              setOpenMenu(false);
              // const main = document.getElementsByClassName("mainContent");
              // if(main != null){
              //   main[0].scrollTop();
              // }
            }}
          >
            <ListItemButton>
              <ListItemText>
                Contact
              </ListItemText>
            </ListItemButton>
          </Link>
          <Divider />
        </List>
      </Drawer>
      <IconButton
        // sx={{paddingRight:"2rem"}}
        // sx={{position:"static"}}
        onClick={() => {
          setOpenMenu(!openMenu);
        }}
      >
        <MenuIcon sx={{ color: "primary.contrastText" }} fontSize="large" />
      </IconButton>
    </React.Fragment>
  );
};

export default MenuDrawer;
