import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import MainContent from "../../components/mainContent-Component";
import { PageTitle } from "../../components/titles";

import "../../../node_modules/react-modal-video/css/modal-video.css";
import ModalVideo from "react-modal-video";
import Footer from "../../components/footer";
import { useTheme } from "@emotion/react";
import { Animate, AnimateGroup } from "react-simple-animate";
import setHeight from "../../utils/setHeight";

const Media = () => {
  const [listVideos, setListVideos] = useState([]);
  const [currentVideoID, setCurrentVideoID] = useState(null);
  const [isOpen, setOpen] = useState(false);
  // const [boundingRect, setBoundingRect] = useState({});

  const theme = useTheme();
  // var breakPoints = [];
  var breakPoints = [];
  breakPoints["xs"] = [useMediaQuery(theme.breakpoints.only("xs")), 1, 40];
  breakPoints["sm"] = [useMediaQuery(theme.breakpoints.only("sm")), 1, 60];
  breakPoints["md"] = [useMediaQuery(theme.breakpoints.only("md")), 2, 60];
  breakPoints["lg"] = [useMediaQuery(theme.breakpoints.only("lg")), 2, 60];
  breakPoints["xl"] = [useMediaQuery(theme.breakpoints.only("xl")), 2, 60];

  const valid = Object.keys(breakPoints).filter((key) => {
    if (breakPoints[key][0]) {
      return breakPoints[key];
    }
    return false;
  });

  const col = breakPoints[valid[0]][1];

  const requestYoutubeInfo = async () => {
    //PL87Ucl2jq7OM4iSwFsObFaJv9sylSzRk0
    // const playlistID = "PLYJAsJMaZ3H3biaXe2QYRO_eJLVbsrFkG";
    const playlistID = "PL87Ucl2jq7OM4iSwFsObFaJv9sylSzRk0";
    const apiK = "AIzaSyDhqfDlXB8dv4zwEv8LeKen-haw6VMM-nw";

    const res = await axios.get(
      "https://www.googleapis.com/youtube/v3/playlistItems",
      {
        params: {
          part: "snippet,contentDetails",
          key: apiK,
          playlistId: playlistID,
        },
      }
    );
    //console.log(res.data);
    let playlistItems = [];
    const resp = res.data;
    if (resp) {
      Object.entries(resp.items).forEach((data) => {
        playlistItems.push({
          title: data[1].snippet.title,
          videoId: data[1].contentDetails.videoId,
          thumbnails: data[1].snippet.thumbnails,
        });
      });

      setListVideos(playlistItems);
    }
  };

  // call function when component is loaded 1 time
  useEffect(() => {
    window.scrollTo(0, 0);

    (async () => {
      await requestYoutubeInfo();
    })();

    setHeight();
  }, []);

  const props = {
    start: { opacity: 0 },
    end: { opacity: 1 },
    duration: 0.2,
  };

  const propsYT = {
    start: { opacity: 0 },
    end: { opacity: 1 },
    duration: 0.1,
  };

  const [animateYT, setAnimateYT] = useState(false);

  return (
    <>
      <Animate {...props} play onComplete={() => setAnimateYT(true)}>
        <Box
          className="callResize"
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          <MainContent
            id={"mediaMainContent"}
            paddingX={{ xs: "2rem", sm: "4rem", md: "2rem", lg: "1rem" }}
            // height={`calc(${boundingRect.height} - 380px)`}
            // height={`100vb`}
            // height="100vh"
          >
            {/* <Grid xs sm  md lg xl></Grid> */}
            <PageTitle>Media</PageTitle>
            <AnimateGroup play={animateYT}>
              <Box display={"flex"} justifyContent={"center"}>
                <ImageList sx={{ width: "80%" }} cols={col} gap={10}>
                  {listVideos.map((item, index) => {
                    return (
                      <>
                        <Animate {...propsYT} sequenceIndex={index + 1}>
                          <ImageListItem
                            onClick={() => {
                              setCurrentVideoID(item.videoId);
                              setOpen(true);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            <img
                              src={`${item.thumbnails.high.url}?w=248&fit=crop&auto=format`}
                              srcSet={`${item.thumbnails.high.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                              alt={item.title}
                              loading="eager"
                            />
                            <ImageListItemBar
                              title={item.title}
                              subtitle={item.title}
                            />
                          </ImageListItem>
                        </Animate>
                      </>
                    );
                  })}
                </ImageList>
              </Box>
            </AnimateGroup>
          </MainContent>
          <Box>
            <ModalVideo
              channel="youtube"
              youtube={{ mute: 0, autoplay: 0 }}
              isOpen={isOpen}
              videoId={currentVideoID}
              onClose={() => {
                setOpen(false);
              }}
            />
          </Box>
          <Footer />
        </Box>
      </Animate>
    </>
  );
};

export default Media;
