import { Box } from "@mui/material";

const MainContent = ({
  children,
  backgroundColor = "transparent",
  id = null,
  height = null,
  // paddingX = { xs: "20px", sm: "30px", md: "60px" },
  paddingX = { xs: "20px", sm: "30px", md: "60px" },
}) => {
  return (
    <Box bgcolor={backgroundColor} paddingY={"2rem"} className="mainContent">
      <Box
        height={height}
        // border={"solid"}
        display={"flex"}
        flexDirection={"column"}
        // height={height}
        alignContent={"space-evenly"}
        maxWidth={"1100px"}
        margin={"auto"}
        paddingX={paddingX}
        id={id}
      >
        {children}
      </Box>
    </Box>
  );
};

export default MainContent;
