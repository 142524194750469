const pressReleases = [
  {
    title:
      "SSRIA Spotlight Series: Featuring 4 Elements Integrated Design",
    media: "SSRIA - Smart Sustainable Resilient Infrastructure Association",
    date: "2022-11-23",
    link: "https://www.ssria.ca/in-the-news/4elements-lead-interview-spotlight",
  },
  {
    title:
      "Calgary Construction Association raises concerns over retrofit goals in city’s climate strategy",
    media: "Global News",
    date: "2022-06-13",
    link: "https://globalnews.ca/news/8917603/calgary-construction-association-raises-concerns-over-retrofit-goals-in-citys-climate-strategy",
  },
  {
    title:
      "The EnerGuide Rating and current grants, rebates and funding opportunities",
    media: "Alberta Realtor",
    date: "2021-10-21",
    link: "https://albertarealtor.ca/post/webinar-series-sustainable-housing",
  },
  {
    title:
      "The Conversation on retrofitting older homes for energy efficiency is about to heat up",
    media: "Calgary Herald",
    date: "2021-01-22",
    link: "https://calgaryherald.com/life/homes/the-conversation-on-retrofitting-older-homes-for-energy-efficiency-is-about-to-heat-up",
  },
  {
    title:
      " Community planning, construction where building green can have biggest impact",
    media: "Calgary Herald",
    date: "2020-03-27",
    link: "https://calgaryherald.com/life/homes/community-planning-construction-where-building-green-can-have-biggest-impact",
  },
  {
    title:
      "Small changes can add up to big results when it comes to energy efficiency",
    media: "Calgary Herald",
    date: "2020-03-20",
    link: "https://calgaryherald.com/life/homes/new-homes/small-changes-can-add-up-to-big-results-when-it-comes-to-energy-efficiency",
  },
  {
    title:
      "Renovating for energy efficiency can boost savings, comfort and even health",
    media: "CBC News",
    date: "2020-01-18",
    link: "https://www.cbc.ca/news/canada/calgary/energy-efficient-renos-1.5431054",
  },
  {
    title: "Local experts, businesses highlight Calgary Renovation Show",
    media: "Calgary SUN",
    date: "2020-01-18",
    link: "https://calgarysun.com/life/homes/local-experts-businesses-highlight-calgary-renovation-show",
  },
  {
    title: "Easy ways to make your home more energy efficient",
    media: "Global News",
    date: "2020-01-17",
    link: "https://calgarysun.com/life/homes/local-experts-businesses-highlight-calgary-renovation-show",
  },
  {
    title:
      "Bucci Developments' Radius earns coveted LEED Platinum certification",
    media: "Calgary Herald",
    date: "2019-04-26",
    link: "https://calgaryherald.com/life/homes/new-homes/bucci-developments-radius-earns-coveted-leed-platinum-certification",
  },
  {
    title: "Industry volunteers recognized at BILD Alberta conference",
    media: "Calgary SUN",
    date: "2018-09-15",
    link: "https://calgarysun.com/life/homes/industry-volunteers-recognized-at-bild-alberta-conference",
  },
  {
    title:
      "'An inspiration to so many,'Brookfield leader inducted into BILD Alberta Hall of Fame",
    media: "Calgary Herald",
    date: "2018-09-14",
    link: "https://calgaryherald.com/life/homes/new-homes/an-inspiration-to-so-many-brookfield-leader-inducted-into-bild-alberta-hall-of-fame",
  },
  {
    title: "LEED free for Fort McMurray projects",
    media: "Construction Canada",
    date: "2016-08-02",
    link: "https://www.constructioncanada.net/leed-free-for-fort-mcmurray-projects/",
  },
  {
    title:
      "Designers, scientists look to reduce air conditioning's environmental impact",
    media: "CBC News",
    date: "2016-07-15",
    link: "https://www.cbc.ca/news/technology/scientists-designers-air-conditioning-improvement-1.3679609",
  },
  {
    title: "A real LEEDer in green building is ready to go",
    media: "Medicine Hat News",
    date: "2015-09-28",
    link: "https://www.pressreader.com/canada/medicine-hat-news/20150820/page/4",
  },
  {
    title: "Home heat map tool good for wallets, earth: U of C experts",
    media: "CBC News",
    date: "2013-11-12",
    link: "https://www.cbc.ca/news/canada/calgary/home-heat-map-tool-good-for-wallets-earth-u-of-c-experts-1.2423843",
  },
  {
    title:
      "Oooomm... Find Zen in Aspen, Avalon creates energy-efficient niche in its LEED homes",
    media: "Calgary Herald",
    date: "2010-08-21",
    link: "https://www.pressreader.com/canada/calgary-herald/20100821/299427941051273",
  },
  {
    title: "Stampede Lotto Home a Dream Come True",
    media: "Calgary SUN",
    date: "2010-07-03",
    link: "https://www.pressreader.com/canada/calgary-sun-homes/20100703/page/10",
  },
  {
    title: "Duo keeping green homes affordable",
    media: "Regina Leader-Post",
    date: "2009-05-02",
    link: "https://www.pressreader.com/canada/regina-leader-post/20090502/page/56",
  },
  {
    title: "Go Green and Go Home",
    media: "Edmonton Journal",
    date: "2009-04-25",
    link: "https://www.pressreader.com/canada/edmonton-journal/20090425/282673273265440",
  },
];

export default pressReleases;