import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const TitleBiColor = ({
  part1,
  part2,
  hSize = "h2",
  revert = false,
  upperCase = true,
}) => {
  return (
    <>
      {revert === false ? (
        <Typography
          variant={hSize}
          marginBottom={"2rem"}
          fontFamily="Oswald"
          textTransform={upperCase ? "uppercase" : ""}
        >
          <span style={{ color: "#0B7B04" }}>{part1}</span>
          <span style={{ color: "#191717" }}>{part2}</span>
        </Typography>
      ) : (
        <Typography
          variant={hSize}
          marginBottom={"2rem"}
          fontFamily="Oswald"
          textTransform={upperCase ? "uppercase" : ""}
        >
          <span style={{ color: "#191717" }}>{part2}</span>
          <span style={{ color: "#0B7B04" }}>{part1}</span>
        </Typography>
      )}
    </>
  );
};

const TitleSingleColor = ({ children, hSize = "h2" }) => {
  return (
    <>
      <Typography variant={hSize} marginBottom={"2rem"} color={"#191717"}>
        {children}
      </Typography>
    </>
  );
};

const PageTitle = ({ children, color = "secondary" }) => {
  return (
    <Box
      width={"100%"}
      display={"flex"}
      justifyContent={"center"}
      textAlign={"center"}
      margin={"1rem 0 3rem 0"}
    >
      <Typography
        color={color}
        variant=""
        textTransform={"uppercase"}
        fontFamily="Oswald"
        fontSize={"2.5rem"}
      >
        {children}
      </Typography>
    </Box>
  );
};

const TitleSpecial = ({ firstWord, secondWord }) => {
  return (
    <Typography
      marginBottom={"1rem"}
      fontSize={"60px"}
      variant="h3"
      // color={"primary"}
      // sx={{ whiteSpace: "pre" }}
      // display={"flex"}
      // alignItems={"flex-start"}
      // textAlign={"left"}
      textTransform={"uppercase"}
    >
      <Box color={"primary"}>{firstWord}</Box>{" "}
      <Box color="secondary">{secondWord}</Box>
    </Typography>
  );
};

const TitleSecondary = ({ children }) => {
  return (
    <Typography
      marginBottom={"50"}
      variant="h3"
      color={"secondary"}
      sx={{ whiteSpace: "pre" }}
      display={"flex"}
      alignItems={"flex-start"}
      textAlign={"left"}
      textTransform={"uppercase"}
    >
      {children}
    </Typography>
  );
};

const TitleSub = ({ children }) => {
  return (
    <Typography
      variant="span"
      color={"secondary.main"}
      sx={{ whiteSpace: "wrap" }}
      fontWeight={"bold"}
      textTransform={"uppercase"}
      fontSize={"20px"}
    >
      {children}
    </Typography>
  );
};

const TitleSub2 = ({ children, marginT="0" }) => {
  return (
    <Typography
      variant="span"
      color={"secondary.main"}
      sx={{ whiteSpace: "wrap" }}
      fontWeight={"bold"}
      fontSize={"20px"}
      marginTop={marginT}
    >
      {children}
    </Typography>
  );
};

const Link4E = ({ children, to="#" }) => {
  return (
    <>
      <Link style={{textDecoration:"none", color:"#0B7B04"}} to={to}>{children}</Link>
    </>
  );
};

export {
  Link4E,
  TitleSpecial,
  TitleSecondary,
  TitleSub,
  TitleSub2,
  TitleBiColor,
  PageTitle,
};
