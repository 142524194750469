import React from 'react';
import { Marker } from 'react-leaflet';
import { DivIcon } from 'leaflet';

import greenIcon from '../../assets/img/leafletIcons/marker-green-full.png';
import greenIconShadow from '../../assets/img/leafletIcons/marker-shadow.png';

const NumericMarker = ({
    number = '',
    position,
    children,
}) => {

    // create icon
    // let customIcon = new Icon({
    //     iconUrl: greenIcon,
    //     shadowUrl: greenIconShadow,
    //     iconSize: [25, 41],
    //     iconAnchor: [5, 55],
    // });

    let customIcon = new DivIcon({
        className: 'locationIcon',
        html: `
            <style>
                .locationIcon{
                    display: flex;
                    width: 100px;
                    align-items: flex-end;
                    justify-items: center;
                }
                .iconMarker
                {
                  width: 25px;
                  height: 41px;
                  z-index: 2;
                }
                .iconMarkerShadow
                {
                  position: absolute;
                  z-index: 1;
                }
                .numberDiv{
                    position: absolute;
                    top: -22px;
                    display: flex;
                    alight-items: center;
                    justify-content:center;
                    // background-color: #eeee;
                    width: 25px;
                    height: 20px;
                    z-index: 2;
                }
                .markerText{
                    position: flex;
                    // font-size: 14px;
                    font-weight: bold;
                    color: #fff;
                    left: 4px;
                    top: 4px;
                    z-index: 3;
                }
            </style>
            <div class="locationIcon">
            <img class="iconMarker" src=${greenIcon} alt="icon"/>
            <img class="iconMarkerShadow" src=${greenIconShadow} alt="iconShadow"/>
            <div class="numberDiv"><span class="markerText">${number}</span></div>
            </div>`,
    });

    return (
        <>
            <Marker
                position={position}
                icon={customIcon}
                children={children}
            >
            </Marker>
        </>
    );
};

export default NumericMarker;