import {
  Box,
  Divider,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import MainContent from "../../components/mainContent-Component";
import { PageTitle } from "../../components/titles";

// js version, instead of JSON because we can't load images (yet)
import residentialItems from "./data/comercialItems";
import Footer from "../../components/footer";
import { FiberManualRecord } from "@mui/icons-material";
import { Animate } from "react-simple-animate";

const Comercial = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const props = {
    start: { opacity: 0 },
    end: { opacity: 1 },
    duration: 0.2,
  };

  return (
    <>
      <Animate {...props} play>
        <MainContent paddingX={{ xs: "2rem", sm: "2rem", md: "1rem" }}>
          <PageTitle>Commercial</PageTitle>

          {/* {Object.values(residentialItems).map((items)=>{
        
      })} */}
          <Grid
            container
            // spacing={-1}
            // gap={2}
            // display={"flex"}
            // flexDirection={"column"}
          >
            {Object.values(residentialItems).map((item) => {
              return (
                <Grid
                  // container
                  // spacing={10}
                  gap={4}
                  // md=}
                  // sx={{marginBottom:"5rem"}}
                  marginBottom={"5rem"}
                  display={"flex"}
                  flexDirection={{
                    xs: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                  }}
                  // gap={2}
                >
                  <Grid item md={3} lg={4}>
                    <Typography variant="h4">{item.title}</Typography>
                    <Divider
                      sx={{
                        backgroundColor: "secondary.main",
                        height: "1px",
                        width: "60%",
                        marginTop: "1rem",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={5}
                    lg={4}
                    display={"inherit"}
                    flexDirection={"column"}
                  >
                    <Typography textAlign={"justify"} color={"text.primary"}>
                      {item.introduction}
                    </Typography>
                    <Divider sx={{ margin: "1rem 0 2rem 0" }} />
                    {item.providerList !== "" ? (
                      <Box>
                        <Typography variant="h5">We Offer:</Typography>
                      </Box>
                    ) : (
                      ""
                    )}
                    <Box>
                      <List dense>
                        {item.providerList !== ""
                          ? item.providerList.map((it) => {
                              return (
                                <ListItem>
                                  <ListItemIcon>
                                    <FiberManualRecord
                                      fontSize="inherit"
                                      color="secondary"
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={`${it}`} sx={{color:"text.primary"}}/>
                                </ListItem>
                              );
                            })
                          : ""}
                      </List>
                    </Box>
                  </Grid>
                  <Grid item md={4} lg={4}>
                    <img
                      alt={item.image}
                      src={item.image}
                      style={{
                        width: "100%",
                        // minWidth: "500px",
                        // minHeight:"20vh",
                        // maxHeight: "40vh",
                        // maxHeight:"1000px",
                        objectFit: "contain",
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </MainContent>
        <Footer />
      </Animate>
    </>
  );
};

export default Comercial;
