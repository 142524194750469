import airtight from '../../../assets/img/services/Commercial/C01_Airtight.JPG';
import commissioning from '../../../assets/img/services/Commercial/C02_Commissioning.JPG'
import energy from '../../../assets/img/services/Commercial/C03b_Energy.JPG'
import verification from '../../../assets/img/services/Commercial/C04_Verification.JPG';
import diagnostic from '../../../assets/img/services/Commercial/C05_Diagnostic.JPG';

const comercialItems = [
  {
    title: "Airtightness Testing",
    introduction:
      "Combine visual inspection, blower door test, infrared photos and building systems knowledge to propose the best solution for your specific type of construction, new and existing.Improving compartmentalization between residential suites in multifamily and row housing improves air quality, safety and is an important requirement in LEED V4 projects.Air tightness testing is becoming increasingly common on large buildings as owners and municipalities push for more energy efficient Buildings.  Specialized multi door testing equipment with advanced datalogging and calculations ensure accurate testing of these complex buildings.",
    providerList: [
      "Full building testing (ASTM E3158, E1186, E779 and CGSPB149.10)",
      "Up to 6 zone guarded testing, building volumes up to 2mil. Cubic Feet",
      "Multi-family residential testing (compartmentalization)",
    ],
    urlIndex: 0,
    image:airtight
  },
  {
    title: "Commissioning",
    introduction:
      "We can ensure your building systems are designed, installed and being operated according to your specific requirements.  Our team of Certified Building Commissioning Professionals and Certified Energy Managers can represent the owner and interact with contractors, vendors, and staff in new or existing buildings to achieve or maintain efficiency and tenant satisfaction.   The commissioning process is critical to reducing deficiencies and warranty issues in today’s complex construction projects.",
    providerList: [
      "Initial planning and support for Owner Performance Requirements",
      "Commissioning Plans",
      "ASHRAE Level 1, 2 & 3 Energy audits",
      "Funtional testing and verification during construction",
      "Building operating plan, preventive maintenance plan",
      "Advanced O&M manuals and owner/tenet training",
    ],
    urlIndex: 1,
    image:commissioning
  },
  {
    title: "Energy Modeling",
    introduction:
      "Our team of Certified Energy Managers and Energy Advisors will coordinate energy modeling with clear reporting and quick turnarounds.   We can help you define your energy goals since preliminary design, as well as optimize the electrical, mechanical, and building infrastructure with a cost-effective approach.  Modeling approaches we use (ASHRAE 90.1, NECB, and 9.36/EnerGuide and PHPP)",
    providerList: [
      "Code compliance Modeling",
      "Energy audits",
      "Pricing and savings calculations",
      "Performance contracting",
      "Integrative design energy analysis",
    ],
    urlIndex: 2,
    image:energy
  },
  {
    title: "Verification & Testing",
    introduction:
      "We can design your ventilation system with equipment selection, sizing of ducts, specify installation and control details, as well as verify if the installation was performed according to the design and is at optimal performance. We can help investigate indoor air quality problems and potential solutions.",
    providerList: [
      "Indoor Air Quality testing for LEED",
      "Radon testing",
      "HRV/ERV Balance Testing",
      "CSA F280-12, CAN/CSA-F326 calculations",
      "Duct leakage testing",
    ],
    urlIndex: 3,
    image:verification
  },
  {
    title: "Building Diagnostics",
    introduction:
      "Combine IR Thermography with blower door testing to reveal the potential causes and extent of warranty related issues in the envelope systems, minimizing the need for more costly and invasive investigation. Assess the extent, cause and potential solutions quickly. Develop remediation plan before starting demolition or any rework for smooth homeowner experience.  All investigation are completed with professional grade equipment and Certified Thermographers and Energy Advisors.",
    providerList: [
      "IR Thermal Scanning",
      "Air tightness testing",
      "HVAC Flow Testing and Room by Room Balancing",
      "Radon Testing",
    ],
    urlIndex: 4,
    image:diagnostic
  },
];

export default comercialItems;