import React, { useEffect, useState } from "react";
import CarouselLanding from "./CarouselLanding";
import AboutUs from "./AboutUs";
import CompanySupports from "./CompanySupports";
import CompanySystems from "./CompanySystems";
// import OurFacts from "./OurFacts";
// import MapFacts from "./MapFacts";
import Footer from "../../components/footer";
import { Box, Fade } from "@mui/material";
import { Animate, AnimateGroup } from "react-simple-animate";

const Landing = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setFadeIn(true);
  }, []);

  const props = {
    start: { opacity: 0 },
    end: { opacity: 1 },
    duration: 0.2,
  };

  return (
    <>
      <AnimateGroup play={fadeIn}>
        <Animate {...props} sequenceIndex={0}>
          <CarouselLanding />
        </Animate>

        <Animate {...props} sequenceIndex={1}>
          <AboutUs />
        </Animate>

        <Animate {...props} sequenceIndex={2}>
          <CompanySupports />
        </Animate>

        <Animate {...props} sequenceIndex={3}>
          <CompanySystems />
        </Animate>

        {/* <Animate {...props} sequenceIndex={4}>
          <OurFacts />
        </Animate>
        <Animate {...props} sequenceIndex={5}>
          <MapFacts />
        </Animate> */}

        <Animate {...props} sequenceIndex={6}>
          <Footer />
        </Animate>
      </AnimateGroup>
    </>
  );
};

export default Landing;
