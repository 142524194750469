
const factNumbers =
    [
        {
            legend: "LEED Projects",
            numbers: 74,
        },
        {
            legend: "Leed Midrise Projects",
            numbers: 6
        },
        {
            legend: "EnerGuide Projects",
            numbers: 125,
        },
        {
            legend: "EnerGuide Homes",
            numbers: 2504,
        },
        {
            legend: "Build Green HD Projects",
            numbers: 36,
        },
        {
            legend: "R2000 Projects",
            numbers:2,
        },
        {
            legend: "Design Projects",
            numbers:86,
        },
        {
            legend: "Net Zero Projects",
            numbers:13,
        },
        {
            legend: "Passive House Projects",
            numbers:3,
        },
        {
            legend: "Total Projects *",
            numbers:572,
        }
    ];

export default factNumbers;


// before 2023
// const factNumbers =
//     [
//         {
//             legend: "LEED Projects",
//             numbers: 68,
//         },
//         {
//             legend: "Leed Midrise Projects",
//             numbers: 6
//         },
//         {
//             legend: "EnerGuide Projects",
//             numbers: 115,
//         },
//         {
//             legend: "EnerGuide Homes",
//             numbers: 1657,
//         },
//         {
//             legend: "Build Green HD Projects",
//             numbers: 26,
//         },
//         {
//             legend: "R2000 Projects",
//             numbers:2,
//         },
//         {
//             legend: "Design Projects",
//             numbers:82,
//         },
//         {
//             legend: "Net Zero",
//             numbers:10,
//         },
//         {
//             legend: "Passive House Projects",
//             numbers:3,
//         },
//         {
//             legend: "Total Projects *",
//             numbers:478,
//         }
//     ];

// export default factNumbers;