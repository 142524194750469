// const projectsLocation = [];

const locationsMain = [
  ["GrandTotal", 455, 0, 0],
  ["AB", 455, 51.0253267, -114.0498685],
  ["BC", 38, 49.284061, -123.123556],
  ["NU", 2, 63.751203, -68.521663],
  ["NWY", 6, 62.453042, -114.371365],
  ["ON", 3, 45.421106, -75.690308],
  ["SK", 2, 52.136477, -106.667339],
  ["USA", 2, 37.1147865, -107.637511],
  ["QC", 1, 46.856283, -71.4817737],
];

const locationsSpecific = [
  ["Airdrie",2020, 2,"AB",51.286, -114.0109],
  ["Attons Lake, SK",2020, 1,"SK",52.837492, -108.858097],
  ["Banff",2020, 1,"AB",51.1778, -115.567],
  ["Bragg Creek",2020, 2,"AB",50.9515, -114.5629],
  ["Calgary",2020, 280,"AB",51.0253267, -114.0498685],
  ["Canmore",2020,36,"AB",51.0866897, -115.348113],
  ["CANYON CREEK AB",2020, 1,"AB",55.364318, -115.041806],
  ["Carstairs",2020, 1,"AB",51.563216, -114.1000016],
  ["Cold Lake",2020, 1,"AB",54.46046, -110.192871],
  ["Comox BC",2020, 1,"BC",49.6966177,-124.9420303],
  ["Creston",2020, 1,"BC",49.095469, -116.513257],
  ["Crossfield",2020, 1,"AB",51.4272738, -114.0309938],
  ["Didsbury, AB",2020, 1,"AB",51.661167, -114.136298],
  ["Duchess, AB",2020, 2,"AB",50.7305674, -111.9055311],
  ["Durango, CO USA",2020, 1,"USA",37.2725422,-107.9023788],
  ["Edmonton",2020, 38,"AB",53.535411, -113.507996],
  ["Fairmont, BC",2020, 1,"BC",50.334858, -115.851358],
  ["Fort Sask.",2020, 1,"AB",53.712965, -113.214111],
  ["FT MCMURRY",2020, 2,"AB",56.7292, -111.389],
  ["Golden BC",2020, 1,"BC",51.2975903, -116.9646541],
  ["Grand Forks",2020, 1,"BC",49.0293116,-118.4648358],
  ["GRANDE PRAIRIE",2020, 1,"AB",55.164605, -118.797723],
  ["Guelph, ON",2020, 1,"ON",43.533783, -80.2306337],
  ["High River",2020, 3,"AB",50.5834, -113.86900000000003],
  ["Invermere",2020, 1,"BC",50.506266, -116.029090],
  ["Iqaluit, NU",2020, 2,"NU",63.751203, -68.521663],
  ["Kamloops",2020, 2,"BC",50.6758269, -120.3394159],
  ["Kelowna",2020, 6,"BC",49.889326, -119.498291],
  ["Kimberley, BC",2020, 2,"BC",49.663358, -115.980557],
  ["Langdon",2020, 1,"AB",50.9735697, -113.6769252],
  ["Langford",2020, 1,"BC",48.4636898,-123.5402437],
  ["Langley, BC",2020, 1,"BC",49.103943, -122.659772],
  ["LETHBRIDGE",2020, 4,"AB",49.6943, -112.85199999999998],
  ["MEDICINE HAT",2020, 15,"AB",50.043, -110.67899999999997],
  ["Millarville",2020, 1,"AB",50.7581, -114.32499999999999],
  ["Nanaimo",2020, 1,"BC",49.184544, -123.956421],
  ["Okotoks",2020, 4,"AB",50.7253685, -113.975082],
  ["Ottawa",2020, 2,"ON",45.421106, -75.690308],
  ["Peerless Lake",2020, 1,"AB",56.6724265, -114.5756905],
  ["Penticton",2020, 1,"BC",49.500268, -119.593605],
  ["Ponoka",2020, 1,"AB",52.6794142, -113.5871706],
  ["Quebec",2020, 2,"QC",46.814213, -71.207491],
  ["Raymond AB",2020, 1,"AB",49.471720, -112.651170],
  ["RED DEER",2020, 2,"AB",52.268112, -113.811241],
  ["Revelstoke, BC",2020, 1,"BC",50.998, -118.19600000000003],
  ["Rochester, AB",2020, 1,"AB",54.370913, -113.455015],
  ["Rockyview",2020, 3,"AB",51.112574, -114.330398],
  ["Salmon Arm, BC",2020, 1,"BC",50.686057, -119.290286],
  ["STRATHMORE",2020, 2,"AB",51.038053, -113.399786],
  ["Summerland BC",2020, 1,"BC",49.607511, -119.676647],
  ["Surrey, BC",2020, 1,"BC",49.190668, -122.843934],
  ["Toronto",2020, 1,"ON",43.7181557,-79.5181396],
  ["TURNER VALLEY",2020, 2,"AB",50.676903, -114.277992],
  ["Vancouver, BC",2020, 16,"BC",49.284061, -123.123556],
  ["Vernon",2020, 1,"BC",50.267458, -119.272268],
  ["WA, USA",2020, 1,"USA",46.062387, -118.334756],
  ["Waiparous, AB",2020, 1,"AB",51.284754, -114.840377],
  ["Wetaskiwin",2020, 3,"AB",52.970553, -113.378816],
  ["Yellowknife, NWT",2020, 6,"NWT",62.453042, -114.371365],
  ["YORKTON SASK",2020, 1,"SK",51.216996, -102.474004],
];

export { locationsMain, locationsSpecific };

// const locationsSpecific = [
//   ["Airdrie",2020, 2,"AB",51.286, -114.0109],
//   ["Attons Lake, SK",2020, 1,"SK",52.837492, -108.858097],
//   ["Banff",2020, 1,"AB",51.1778, -115.567],
//   ["Bragg Creek",2020, 2,"AB",50.9515, -114.5629],
//   ["Calgary",2020, 280,"AB",51.0253267, -114.0498685],
//   ["Canmore",2020,36,"AB",51.0866897, -115.348113],
//   ["CANYON CREEK AB",2020, 1,"AB",55.364318, -115.041806],
//   ["Carstairs",2020, 1,"AB",51.563216, -114.1000016],
//   ["Cold Lake",2020, 1,"AB",54.46046, -110.192871],
//   ["Comox BC",2020, 1,"BC",49.6966177,-124.9420303],
//   ["Creston",2020, 1,"BC",49.095469, -116.513257],
//   ["Crossfield",2020, 1,"AB",51.4272738, -114.0309938],
//   ["Didsbury, AB",2020, 1,"AB",51.661167, -114.136298],
//   ["Duchess, AB",2020, 2,"AB",50.7305674, -111.9055311],
//   ["Durango, CO USA",2020, 1,"USA",37.2725422,-107.9023788],
//   ["Edmonton",2020, 38,"AB",53.535411, -113.507996],
//   ["Fairmont, BC",2020, 1,"BC",50.334858, -115.851358],
//   ["Fort Sask.",2020, 1,"AB",53.712965, -113.214111],
//   ["FT MCMURRY",2020, 2,"AB",56.7292, -111.389],
//   ["Golden BC",2020, 1,"BC",51.2975903, -116.9646541],
//   ["Grand Forks",2020, 1,"BC",49.0293116,-118.4648358],
//   ["GRANDE PRAIRIE",2020, 1,"AB",55.164605, -118.797723],
//   ["Guelph, ON",2020, 1,"ON",43.533783, -80.2306337],
//   ["High River",2020, 3,"AB",50.5834, -113.86900000000003],
//   ["Invermere",2020, 1,"BC",50.506266, -116.029090],
//   ["Iqaluit, NU",2020, 2,"NU",63.751203, -68.521663],
//   ["Kamloops",2020, 2,"BC",50.6758269, -120.3394159],
//   ["Kelowna",2020, 6,"BC",49.889326, -119.498291],
//   ["Kimberley, BC",2020, 2,"BC",49.663358, -115.980557],
//   ["Langdon",2020, 1,"AB",50.9735697, -113.6769252],
//   ["Langford",2020, 1,"BC",48.4636898,-123.5402437],
//   ["Langley, BC",2020, 1,"BC",49.103943, -122.659772],
//   ["LETHBRIDGE",2020, 4,"AB",49.6943, -112.85199999999998],
//   ["MEDICINE HAT",2020, 15,"AB",50.043, -110.67899999999997],
//   ["Millarville",2020, 1,"AB",50.7581, -114.32499999999999],
//   ["Nanaimo",2020, 1,"BC",49.184544, -123.956421],
//   ["Okotoks",2020, 4,"AB",50.7253685, -113.975082],
//   ["Ottawa",2020, 2,"ON",45.421106, -75.690308],
//   ["Peerless Lake",2020, 1,"AB",56.6724265, -114.5756905],
//   ["Penticton",2020, 1,"BC",49.500268, -119.593605],
//   ["Ponoka",2020, 1,"AB",52.6794142, -113.5871706],
//   ["Quebec",2020, 2,"QC",46.814213, -71.207491],
//   ["Raymond AB",2020, 1,"AB",49.471720, -112.651170],
//   ["RED DEER",2020, 2,"AB",52.268112, -113.811241],
//   ["Revelstoke, BC",2020, 1,"BC",50.998, -118.19600000000003],
//   ["Rochester, AB",2020, 1,"AB",54.370913, -113.455015],
//   ["Rockyview",2020, 3,"AB",51.112574, -114.330398],
//   ["Salmon Arm, BC",2020, 1,"BC",50.686057, -119.290286],
//   ["STRATHMORE",2020, 2,"AB",51.038053, -113.399786],
//   ["Summerland BC",2020, 1,"BC",49.607511, -119.676647],
//   ["Surrey, BC",2020, 1,"BC",49.190668, -122.843934],
//   ["Toronto",2020, 1,"ON",43.7181557,-79.5181396],
//   ["TURNER VALLEY",2020, 2,"AB",50.676903, -114.277992],
//   ["Vancouver, BC",2020, 16,"BC",49.284061, -123.123556],
//   ["Vernon",2020, 1,"BC",50.267458, -119.272268],
//   ["WA, USA",2020, 1,"USA",46.062387, -118.334756],
//   ["Waiparous, AB",2020, 1,"AB",51.284754, -114.840377],
//   ["Wetaskiwin",2020, 3,"AB",52.970553, -113.378816],
//   ["Yellowknife, NWT",2020, 6,"NWT",62.453042, -114.371365],
//   ["YORKTON SASK",2020, 1,"SK",51.216996, -102.474004],
// ];
