import Header from "../components/header/header";
import Landing from "../pages/landing";
import Contact from "../pages/contact/contact";
import Residential from "../pages/services/residential";
import Comercial from "../pages/services/comercial";
import Team from "../pages/about/team";
import Media from "../pages/about/media";
import News from "../pages/about/news";
import { createBrowserRouter } from "react-router-dom";
import { Box } from "@mui/material";

const routes = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Header />
        <Box marginBottom={"72px"} />
        <Landing />
      </>
    ),
  },
  {
    path: "/contact",
    element: (
      <>
        <Header />
        <Box marginBottom={"72px"} />
        <Contact />
      </>
    ),
  },
  {
    path: "/residential",
    element: (
      <>
        <Header />
        <Box marginBottom={"72px"} />
        <Residential />
      </>
    ),
  },
  {
    path: "/comercial",
    element: (
      <>
        <Header />
        <Box marginBottom={"72px"} />
        <Comercial />
      </>
    ),
  },
  {
    path: "/team",
    element: (
      <>
        <Header />
        <Box marginBottom={"72px"} />
        <Team />
      </>
    ),
  },
  {
    path: "/media",
    element: (
      <>
        <Header />
        <Box marginBottom={"72px"} />
        <Media />
      </>
    ),
  },
  {
    path: "/news",
    element: (
      <>
        <Header />
        <Box marginBottom={"72px"} />
        <News />
      </>
    ),
  },
]);

export default routes;
