import {
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  colors,
  Button,
} from "@mui/material";
import React from "react";
import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";
// import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import elogoWhite from "../assets/img/4ElogoWhite.png";
import { Link } from "react-router-dom";

const styles = {
  pageLinkHover: {
    textDecoration: "none",
    "&:hover": {
      color: "white",
    },
  },
};

const Footer = () => {
  // TODO:FIX FOOTING POSITION
  return (
    <>
      {/* <MainContent backgroundColor="#2F3430"> */}
      <Box sx={{ position: "relative", bottom: "0px", width: "100%" }}>
        {/* <Box sx={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0}}> */}
        <Box sx={{ backgroundColor: "#2F3430" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",

              padding: "2rem",
              maxWidth: "1140px",
              margin: "auto",
            }}
          >
            {/* <Box height={"400px"}> */}
            <Grid
              //
              display={"flex"}
              flexDirection={{
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              }}
              justifyContent={"space-between"}
            >
              <Box display={"flex"} justifyContent={{ sm: "center" }}>
                {/* <Typography variant="h4" color={"white"} marginBottom={"1rem"}>
                  4 Elements
                </Typography> */}
                <img
                  src={elogoWhite}
                  alt="logo 4 elements black white"
                  height={"40rem"}
                />
              </Box>
              <Box
                display={"flex"}
                flexDirection={{ xs: "column", sm: "column", md: "row" }}
              >
                <Grid
                  display={"flex"}
                  flexDirection={"column"}
                  minWidth={"10rem"}
                  marginLeft={{ md: "2rem" }}
                >
                  <Typography
                    variant="h5"
                    color={"primary.light"}
                    marginTop={{ xs: "0.5rem" }}
                  >
                    Services
                  </Typography>
                  <Divider color="secondary.main" sx={{ marginY: "0.5rem" }} />
                  <Typography
                    color={"#9A9A9A"}
                    sx={{ ":hover": { color: "#EBECEB" } }}
                  >
                    <Link
                      onClick={animateScroll.scrollToTop}
                      style={{ textDecoration: "none", color: "inherit" }}
                      to={"/residential"}
                    >
                      Residential Services
                    </Link>
                  </Typography>

                  {/* <Typography
                    color={"#9A9A9A"}
                    sx={{ ":hover": { color: "#EBECEB" } }}
                  >
                    <Link
                      onClick={animateScroll.scrollToTop}
                      style={{ textDecoration: "none", color: "inherit" }}
                      to={"/comercial"}
                    >
                      Commercial
                    </Link>
                  </Typography> */}
                </Grid>
                <Grid
                  display={"flex"}
                  flexDirection={"column"}
                  minWidth={"10rem"}
                  marginLeft={{ md: "2rem" }}
                  marginBottom={{ sm: "2rem" }}
                >
                  <Typography
                    variant="h5"
                    color={"primary.light"}
                    marginTop={{ xs: "0.5rem" }}
                  >
                    About
                  </Typography>
                  <Divider color="secondary.main" sx={{ marginY: "0.5rem" }} />
                  <Typography
                    color={"#9A9A9A"}
                    sx={{ ":hover": { color: "#EBECEB" } }}
                  >
                    <Link
                      onClick={animateScroll.scrollToTop}
                      style={{ textDecoration: "none", color: "inherit" }}
                      to={"/team"}
                    >
                      Team
                    </Link>
                  </Typography>
                  {/* <Typography
                    color={"#9A9A9A"}
                    sx={{ ":hover": { color: "#EBECEB" } }}
                  >
                    <Link
                      onClick={animateScroll.scrollToTop}
                      style={{ textDecoration: "none", color: "inherit" }}
                      to={"/media"}
                    >
                      Media
                    </Link>
                  </Typography>
                  <Typography
                    color={"#9A9A9A"}
                    sx={{ ":hover": { color: "#EBECEB" } }}
                  >
                    <Link
                      onClick={animateScroll.scrollToTop}
                      style={{ textDecoration: "none", color: "inherit" }}
                      to={"/news"}
                    >
                      News
                    </Link>
                  </Typography> */}
                  <Typography
                    color={"#9A9A9A"}
                    sx={{ ":hover": { color: "#EBECEB" } }}
                  >
                    <Link
                      style={{ textDecoration: "none", color: "inherit" }}
                      to={"https://4egreenhomes.medium.com/"}
                      target="#"
                    >
                      Blog
                    </Link>
                  </Typography>
                  <Typography
                    color={"#9A9A9A"}
                    sx={{ ":hover": { color: "#EBECEB" } }}
                  >
                    <Link
                      onClick={animateScroll.scrollToTop}
                      style={{ textDecoration: "none", color: "inherit" }}
                      to={"/contact"}
                    >
                      Contact
                    </Link>
                  </Typography>
                </Grid>
                <Grid
                  display={"flex"}
                  flexDirection={"column"}
                  minWidth={"10rem"}
                  marginLeft={{ md: "2rem" }}
                  marginBottom={{ sm: "2rem" }}
                >
                  <Typography
                    variant="h5"
                    color={"primary.light"}
                    marginTop={{ xs: "0.5rem" }}
                  >
                    Follow Us
                  </Typography>
                  <Divider color="secondary.main" sx={{ marginY: "0.5rem" }} />
                  <Box display={"flex"} flexDirection={"row"}>
                    <Link
                      to={"https://twitter.com/4egreenhomes?lang=en"}
                      target="#"
                    >
                      <IconButton>
                        <Twitter
                          htmlColor="#9A9A9A"
                          sx={{ ":hover": { color: "#EBECEB" } }}
                        />
                      </IconButton>
                    </Link>
                    <Link
                      to={"https://www.facebook.com/4EGreenhomes/"}
                      target="#"
                    >
                      <IconButton>
                        <Facebook
                          htmlColor="#9A9A9A"
                          sx={{ ":hover": { color: "#EBECEB" } }}
                        />
                      </IconButton>
                    </Link>
                    <Link
                      to={"https://www.instagram.com/4egreenhomes/"}
                      target="#"
                    >
                      <IconButton>
                        <Instagram
                          htmlColor="#9A9A9A"
                          sx={{ ":hover": { color: "#EBECEB" } }}
                        />
                      </IconButton>
                    </Link>
                    <Link
                      to={"https://www.linkedin.com/company/4-elements-ltd/"}
                      target="#"
                    >
                      <IconButton>
                        <LinkedIn
                          htmlColor="#9A9A9A"
                          sx={{ ":hover": { color: "#EBECEB" } }}
                        />
                      </IconButton>
                    </Link>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Box>
        {/* </MainContent> */}
        {/* <MainContent backgroundColor="#232724" height="4vb"> */}

        <Box
          sx={{
            backgroundColor: "#232724",
            padding: "2rem 0 2rem 0",
            position: "relative",
            bottom: "0px",
          }}
          color={"white"}
          display={"flex"}
          flexDirection={"column"}
          justifyItems={"center"}
          alignItems={"center"}
          textAlign={"center"}
        >
          <Typography>
            &copy; Copyright <strong>4 Elements Integrated Design LTD.</strong>
          </Typography>
          <Typography>All Rights Reserved.</Typography>
          <Typography>
            Designed by{" "}
            <Link
              // style={{ textDecoration: "none"}}
              className="page-links"
              target="_blank"
              to="http://www.jeisonsantiago.com"
              rel="noreferrer"
              href="http://www.jeisonsantiago.com"
            >
              Jeison Santiago
            </Link>
          </Typography>
        </Box>
        {/* </MainContent> */}
      </Box>
    </>
  );
};

export default Footer;
