import {
  Accordion,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  responsiveFontSizes,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";

import MainContent from "../../components/mainContent-Component";
import { Link4E, PageTitle, TitleSub2 } from "../../components/titles";
import Footer from "../../components/footer";
import {
  ExpandMore,
  ExpandMoreOutlined,
  FiberManualRecord,
  LocationOn,
  Mail,
  Phone,
} from "@mui/icons-material";
import provinces from "../about/data/provinces";
import * as Yup from "yup";
import mapPNG from "../../assets/img/map2.png";
import newsletterPNG from "../../assets/img/newsletter_green.png";
import { Animate } from "react-simple-animate";
import { Link } from "react-router-dom";
import setHeight from "../../utils/setHeight";

let themeContact = createTheme({
  palette: {
    primary: {
      50: "#111",
      main: "#0B7B04",
      light: "#eee",
      contrastText: "#0B7B04",
    },
    secondary: {
      main: "#0B7B04",
      contrastText: "#9A9A9A",
    },
    text: {
      primary: "#747572",
      secondary: "#0B7B04",
    },
  },
});
themeContact = responsiveFontSizes(themeContact);

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    setHeight();
  }, []);

  const [newsletterMessage, setNewsletterMessage] = useState({
    open: false,
    severity: "success",
    message: "message",
    Transition: Fade,
  });

  const handleClose = () => {
    setNewsletterMessage({
      ...newsletterMessage,
      open: false,
    });
  };

  const props = {
    start: { opacity: 0 },
    end: { opacity: 1 },
    duration: 0.15,
  };

  // Options for the observer (which mutations to observe)
  const config = { attributes: true, childList: true, subtree: true };
  function callToast(e) {
    if (!e) return;

    //console.log(e[0].target.innerText);
    if (e[0].target.innerText !== "") {
      setNewsletterMessage({
        type: "Success",
        message: "Thanks for subscribing!",
      });
      // setmessageToast({
      //   type: "Success",
      //   message: "Thanks for subscribing!",
      // });
      // toggleShowA();
    }
  }

  const observer = new MutationObserver(callToast);

  function setNewsLetter(e) {
    e.preventDefault();

    // console.log("SIGN UP");

    if (
      typeof window.VR !== "undefined" &&
      typeof window.VR.SignupForm !== "undefined"
    ) {
      new window.VR.SignupForm({
        id: "30786325579245",
        element: "vr-signup-form-30786325579245",
        endpoint: "https://marketingsuite.verticalresponse.com/se/",
        submitLabel: "Submitting...",
        invalidEmailMessage: "Invalid email address",
        generalErrorMessage: "An error occurred",
        notFoundMessage: "Signup form not found",
        successMessage: "Success!",
        nonMailableMessage: "Nonmailable address",
      });
      observer.observe(document.querySelector("#info-notice"), config);
      console.log("DEFINED VR");
    }

    setNewsletterMessage({
      ...newsletterMessage,
      message: "TEST",
      open: true,
    });
  }

  return (
    <>
      <Animate {...props} play>
        <ThemeProvider theme={themeContact}>
          <Box
            className="callResize"
            display={"flex"}
            flexDirection={"column"}
            // height={`calc(100vh - 72px)`}
            // minHeight={`calc(100vh - 72px)`}
            justifyContent={"space-between"}
            // minHeight={"2000px"}
          >
            <MainContent
            // backgroundColor="#0B7B04"
            // id={"mediaMainContent"}
            // height={`calc(100vh - 72px)`}
            // paddingX={{ xs: "2rem", sm: "4rem", md: "2rem", lg: "1rem" }}
            // height="100vh"
            >
              <PageTitle>Contact Us</PageTitle>
              <Grid container marginBottom={"2rem"}>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  // paddingX={"1rem"}
                  marginBottom={"3rem"}
                >
                  <Box
                    sx={{
                      backgroundImage: `url(${mapPNG})`,
                      // filter: "grayscale(100%)",
                      // boxShadow: "0 0 8px 8px white inset",
                    }}
                    // height={"30vh"}
                    // height={"100%"}
                    width={"100%"}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      padding={"2rem"}
                      // margin={}
                      height={"100%"}
                      // alignContent={"space-around"}
                      justifyContent={"space-around"}
                    >
                      <Typography
                        fontFamily={"Oswald"}
                        color="#EBECEB"
                        variant="h3"
                        // fontWeight={"bold"}
                        letterSpacing={2}
                        marginBottom={"4rem"}
                      >
                        Calgary Office
                      </Typography>
                      <Box display={"flex"} flexDirection={"column"} gap={4}>
                        <Box>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={"mailto:info@4elements.eco"}
                          >
                            <Typography color={"#EBECEB"} fontSize={"1.4rem"}>
                              <Mail /> info@4elements.eco
                            </Typography>
                          </Link>
                        </Box>
                        <Box>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={"tel:+14032505514"}
                          >
                            <Typography color={"#EBECEB"} fontSize={"1.4rem"}>
                              <Phone /> +1 403 250 5514
                            </Typography>
                          </Link>
                        </Box>
                        <Box>
                          <Typography color={"#EBECEB"} fontSize={"1.4rem"}>
                            <LocationOn /> 110 32 AVE NE Calgary, AB - T2E 2G4
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  paddingX={"1rem"}
                >
                  <ContactForm />
                </Grid>
                {/* <Grid
                  marginTop={"2rem"}
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  // paddingX={"1rem"}
                >
                  <Box
                    // margin={"3rem"}
                    display={"flex"}
                    flexDirection={"column"}
                    // alignContent={"end"}
                    alignItems={{ sm: "center", md: "end", lg: "end" }}
                    // justifyContent={"space-around"}
                    sx={{
                      backgroundImage: `url(${newsletterPNG})`,
                      height: "300px",
                      width: "100%",
                    }}
                  >
                    <form
                      class="vr-signup-form"
                      id="vr-signup-form-30786325579245"
                      // style={{ marginLeft: "1rem" }}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        margin={"1rem"}
                        gap={"1rem"}
                      >
                        <Typography
                          marginTop={"2rem"}
                          fontFamily={"Oswald"}
                          color="#EBECEB"
                          variant="h3"
                          // fontWeight={"bold"}
                          letterSpacing={2}
                          // marginBottom={"4rem"}
                        >
                          Get on the list
                        </Typography>
                        <Typography
                          color={"white"}
                          // margin={"1rem 1rem 1rem 0rem"}
                        >
                          Get the latest news and information about Green
                          Building.
                        </Typography>
                        <Box
                          className="input-form"
                          display={"flex"}
                          color={"white"}
                          flexDirection={"column"}
                        >
                          
                          <Box
                            class="vr-field"
                            display={"flex"}
                            // margin={"0rem 1rem 0rem 0rem"}
                          >
                            <TextField
                              sx={{ backgroundColor: "white" }}
                              fullWidth="true"
                              variant="outlined"
                              type="email"
                              // label="Email"
                              placeholder="Email"
                              autoComplete="off"
                              aria-required="true"
                              name="email_address"
                              data-msg="Your Email"
                              // autocomplete="off"
                              required
                            />
                          </Box>
                          <Box display={"flex"} justifyContent={"end"}>
                            <Button
                              sx={{ marginTop: "1rem" }}
                              variant="contained"
                              type="submit"
                              value={"Sign Up"}
                              onClick={setNewsLetter}
                              disabled
                            >
                              <Typography color={"white"}>Sign Up</Typography>
                            </Button>
                          </Box>
                        </Box>
                        <Snackbar
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={newsletterMessage.open}
                          autoHideDuration={2000}
                          onClose={handleClose}
                        >
                          <Alert
                            onClose={handleClose}
                            severity={newsletterMessage.severity}
                            sx={{ width: "100%" }}
                          >
                            {newsletterMessage.message}
                          </Alert>
                        </Snackbar>
                      </Box>
                    </form>
                  </Box>
                </Grid> */}
                <Grid
                  marginTop={"2rem"}
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  // paddingX={"1rem"}
                >

                </Grid>
              </Grid>
            </MainContent>
            <Footer />
          </Box>
        </ThemeProvider>
      </Animate>
    </>
  );
};
const ContactForm = () => {
  //---------------------------------------------------
  const [messageState, setMessageState] = useState({
    open: false,
    severity: "success",
    message: "message",
    Transition: Fade,
  });

  const handleClick = (Transition) => () => {
    setMessageState({
      open: true,
      Transition,
    });
  };

  const handleClose = () => {
    setMessageState({
      ...messageState,
      open: false,
    });
  };
  //---------------------------------------------------

  const [province, setProvince] = useState("Alberta");
  const [errorMessages, setErrorMessages] = useState({
    name: false,
    email: false,
    phone: false,
    city: false,
    message: false,
  });

  const handleProvinceChange = (event) => {
    setProvince(event.target.value);
  };

  let userSchema = Yup.object({
    name: Yup.string().required("Please enter your name."),
    email: Yup.string()
      .email("Please enter a valid email.")
      .required("Please enter you email."),
    phone: Yup.string(),
    city: Yup.string(),
    province: Yup.string(),
    message: Yup.string().required("Please enter your message."),
  });

  const backFocus = () => {
    setErrorMessages({
      name: false,
      email: false,
      phone: false,
      city: false,
      message: false,
    });
  };

  // form reference
  const formRef = useRef(null);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const form = new FormData(event.target);
    console.log(form.get("name"));

    const formData = {
      name: form.get("name"),
      email: form.get("email"),
      phone: form.get("phone"),
      city: form.get("city"),
      province: form.get("province"),
      message: form.get("message"),
    };

    // validation worked...set up the email and send it:
    // -setSpinner(true);
    try {
      const contactMessage = await userSchema.validate(formData, {
        abortEarly: false,
      });
      // console.log(contactMessage);
      const to = "to=info@4elements.eco";
      // const to = "to=jeison.santiago@gmail.com";
      const subject = `subject=From ${formData.name} email: ${formData.email}`;
      const from = `from=${formData.email}`;
      const message = `message=${formData.name}<br />
                           Email:${formData.email}<br />
                           Phone:${formData.phone}<br />
                           City:${formData.city}<br />
                           Province:${formData.province}<br /><br />
                           Message:<br />${formData.message}`;

      fetch("/contact/sendEmail.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: `${to}&${subject}&${message}&${from}`,
      })
        .then((response) => {
          //console.log(response);
          return response.json();
        })
        .then((response) => {
          console.log(response);
          if (response) {
            formRef.current.name.value = "";
            formRef.current.email.value = "";
            formRef.current.phone.value = "";
            formRef.current.city.value = "";
            formRef.current.message.value = "";
            formRef.current.province.value = "Alberta";

            setMessageState({
              open: true,
              severity: "success",
              message: "Message has been sent.",
              Fade,
            });
            // setSpinner(false);
          } else {
            console.log(message);
            // setModalMessage(message);
            // handleShow();
          }
        });
    } catch (error) {
      error.inner.forEach((err) => {
        setErrorMessages((prevState) => ({
          ...prevState,
          [err.params.path]: true,
        }));
      });

      console.log(error.inner[0].message);
      setMessageState({
        open: true,
        severity: "warning",
        message: error.inner[0].message,
        Fade,
      });

      // console.log("FADE!!!");
      // setState(state.open);
    }
  };

  return (
    <form onSubmit={handleFormSubmit} ref={formRef}>
      <Grid container>
        <Grid
          display={"flex"}
          // margin={"auto"}
          // justifyItems={"center"}
          // alignContent={"center"}
          flexDirection={"column"}
          xs={12}
          sm={12}
          md={12}
          gap={"1rem"}
        >
          <TextField
            variant="standard"
            name="name"
            label="Name"
            error={errorMessages.name}
            onFocus={backFocus}
          />
          <Box display={"flex"} flexDirection={"row"} gap={"1rem"}>
            <TextField
              variant="standard"
              name="email"
              label="Email"
              fullWidth
              error={errorMessages.email}
              onFocus={backFocus}
            />
            <TextField
              variant="standard"
              name="phone"
              label="Phone"
              fullWidth
              error={errorMessages.phone}
              onFocus={backFocus}
            />
          </Box>

          <Box display={"flex"} flexDirection={"row"} gap={"1rem"}>
            <TextField
              variant="standard"
              name="city"
              label="City"
              fullWidth
              error={errorMessages.city}
              onFocus={backFocus}
            />
            <FormControl fullWidth>
              <InputLabel sx={{ marginLeft: "-1rem" }} id="provinceSelection">
                Province
              </InputLabel>
              <Select
                variant="standard"
                name="province"
                labelId="provinceSelection"
                fullWidth
                onChange={handleProvinceChange}
              >
                {provinces.map((prov) => {
                  return <MenuItem value={prov}>{prov}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Box>
          <TextField
            variant="standard"
            error={errorMessages.message}
            name="message"
            id="formMessage"
            label="Message"
            fullWidth
            multiline
            rows={5}
            autoComplete="none"
            onFocus={backFocus}
          />
          <Grid display={"flex"} justifyContent={"flex-end"}>
            <Button
              type="submit"
              variant="contained"
              // endIcon={<Send />}
              sx={{ color: "white", backgroundColor: "##0B7B04" }}
            >
              Send Message
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={messageState.open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState.severity}
          sx={{ width: "100%" }}
        >
          {messageState.message}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default Contact;
