import { Box, Grid, Typography } from "@mui/material";
import MainContent from "../../components/mainContent-Component";
import { TitleBiColor, TitleSub } from "../../components/titles";


const AboutUs = () => {
  return (
    <>
      <MainContent>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
          display={"flex"}
          spacing={4}
        >
          <Grid item xs={4} sm={8} md={6}>
            <Box flexDirection={"row"} marginTop={"2rem"}>
              {/* <Typography 
              color={"secondary"}
              variant="h2" sx={{marginBottom:"30px"}}> Sustainable Future.</Typography> */}
              <TitleBiColor part1={"Sustainable"} part2={" Future"} />
            </Box>
            <Box flexDirection={"row"}>
              <TitleSub>
                for all Canadians with better design and construction practices.
              </TitleSub>
            </Box>
          </Grid>
          <Grid item xs={4} sm={8} md={6}>
            <Typography
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              textAlign={"justify"}
              color={"text.primary"}
            >
              <p>
                At 4 Elements our focus is sustainable building
                from pre-construction to final testing. We are at
                the heart of sustainable building in Western
                Canada, helping project teams of builders,
                designers, developers and their clients find a
                pathway to better built, better performing homes
                and buildings.
              </p>
              <p>
                {/* With our team&#39;s experience in architectural design and more
                than 15 years of consulting on some of Canada’s most advanced
                projects, 4 Elements is uniquely qualified to provide detailed
                consultation, support, education and verification of today&#39;s
                high performance sustainable buildings. */}

                With more than 15 years&#39; experience consulting
                on some of Canada&#39;s most advanced projects, 4
                Elements is uniquely qualified to provide detailed
                consultation, support, education and verification
                for today&#39;s high performance sustainable
                buildings.
              </p>
            </Typography>
          </Grid>
          
        </Grid>
      </MainContent>
    </>
  );
};

export default AboutUs;
