const teamItems = [
  // {
  //   index: 0,
  //   name: "Tyler Hermanson",
  //   photoURL: "/images/team/tyler.jpg",
  //   occupations: "Owner | Director Innovation and Design",
  //   certifications: "CBCP, CEM, R.EA (ERS, MURB, SO, ESNH, NZE), LEED QAD",
  //   saitInfo: "Architectural Technologies",
  //   bio: 'Tyler is co-owner and Director of Innovation at 4 Elements. An architectural technologist by training and longtime Energy Advisor, he has over 15 years’ experience in energy efficient, sustainable homes and buildings. Tyler leads a diverse team of green building experts while undertaking more complex energy and verification/ certification projects as a Certified Professional Building Commissioner. Tyler is Primary Quality Assurance Designee as a LEED for Homes Provider. He was extensively involved in the early adoption and development of both Built Green High Density and Net Zero Energy Homes programs in Alberta and continues to support these programs through training. Tyler is a committed volunteer who gives his time to the NBC 2020 Working Group, BILD Calgary Region, and is a member of the Canadian Armed Forces Reserves with 33 Engineer Squadron.',
  //   linkedinLink: "https://www.linkedin.com/in/tyler-hermanson-2553b727",
  //   isDirector: true,
  // },
  // {
  //   index: 1,
  //   name: "Karen Goddard Hermanson",
  //   photoURL: "/images/team/karen.jpg",
  //   occupations: "Owner | Director of Operations",
  //   certifications:
  //     "BFA, CEA, EMIT, LEED AP (Homes), LEED Homes Green Rater, R.EA (MURB, ESNH), Built Green HD Verifier, ITC Level 1 Thermographer",
  //   saitInfo: "Moisture Control Technician",
  //   // bio: "Karen is co-owner and director of 4 Elements. She stepped into the role of Company Manager in 2018, after shifting away from a 20-year career in the performing arts sector. Education and training     opportunities have enabled her to meet the company goal of a broad range of qualified     professionals who provide sustainable building and energy efficiency consulting to builders across     Alberta and BC. Karen is a Certified Energy Auditor, Energy Manager (in Training), Certified Level 1     Thermographer and LEED Green Rater. She leads 4 Elements’ commercial air tightness testing and     verification services and recently led the development of air tightness training courses for builders     and trades. Karen will be working towards qualification as a Certified Air Barrier Specialist (ABAA) in     fall of 2023.",
  //   linkedinLink: "https://www.linkedin.com/in/karen-goddard-hermanson-9a055831",
  //   isDirector: false,
  // },
  {
    index: 2,
    name: "Cooper Le",
    photoURL: "/images/team/CL2.png",
    occupations: "Sr. Manager - Production and Operations | Master Energy Advisor",
    certifications:
      "AT, R.EA (ERS, MURB, ESNH, NZE, QAS), LEED AP (Homes, BD+C), LEED Homes Green Rater, MCT, IDP Facilitator, ITC Level 1 Thermographer",
    saitInfo: "Architectural Technologies",
    // bio: "A graduate of the Southern Alberta Institute of Technology (SAIT), Cooper joined 4 Elements in 2011 as a residential draftsman, then progressing to LEED Green Rating and Registered Energy Advisor. In    2022, Cooper moved into a leadership role and now manages 4 Elements’ growing team of Energy    Advisors. He leads the company’s Net Zero labeling work with CHBA, while assisting with consulting    projects, and larger, more complex air tightness testing. He delivers most of the internal training,    mentoring, and management of 4 Elements Energy Advisors, as well as air tightness training for    builders and trades. Cooper recently submitted his application to the Canadian Association of    Consulting Energy Advisors (CACEA) for their new Master Energy Advisor designation, which    recognizes extensive work in the industry.",
    linkedinLink: "https://www.linkedin.com/in/cooper-le-072001180/",
    isDirector: false,
  },
  {
    index: 3,
    name: "Fiona Kennedy",
    photoURL: "/images/team/FK2.png",
    occupations: "Sr. Manager – Business Administration | Service Organization Manager",
    certifications: "Hons BA, Cert. Technical Writing",
    saitInfo: "",
    // bio: "Fiona is an experienced administrator who takes pride in providing a high standard of organization and customer service. Prior to joining 4 Elements, Fiona enjoyed a 30-year career in technical production for the performing arts and brings a background in project management, budget administration, and process development and implementation to her work. As Service Organization Manager, Fiona oversees the intake, processing, and final billing of energy modeling and testing. This role includes coordination of the 4 Elements team’s site work, equipment assignments, and communication with clients. As Technical Writer, Fiona develops reporting templates and content in coordination with senior staff as well as completing final editing and formatting.",
    linkedinLink: "",
    isDirector: false,
  },
  {
    index: 4,
    name: "Brian Chaisson",
    photoURL: "/images/team/BDC.png",
    occupations: "Energy Advisor in Training",
    certifications: "",
    saitInfo: "",
    // bio: "Brian brings extensive customer service experience and previous work as an electrician to his work with 4 Elements. His attention to detail is of exceptional value as a field Energy Advisor in Training,    and he is responsible for the majority of 4 Elements’ on-site testing and reviews. Brian is currently    under mentorship with senior staff as he works towards Energy Advisor registration.",
    linkedinLink: "",
    isDirector: false,
  },
  {
    index: 5,
    name: "Maka Onwudiwe",
    photoURL: "/images/team/MO.png",
    occupations: "Energy Advisor | Draftsperson",
    certifications: "AT, R.EA (ERS, MURB)",
    saitInfo: "Architectural Technologies",
    // bio: "A graduate of SAIT’s Architectural Technologies program, Maka brings an understanding of code compliant construction and an interest in sustainable design to her work with 4 Elements. Maka provides modeling and compliance documentation for many of 4 Elements builder clients, as well    completing Heat Loss Heat Gain CSA F280 Room by Room calculations for those clients who are    planning for Net Zero certification. Maka has contributed her drafting skills to 4 Elements design    projects and is a member of the site testing team.",
    linkedinLink: "",
    isDirector: false,
  },
  {
    index: 6,
    name: "Anand Patel",
    photoURL: "/images/team/AP.png",
    occupations: "Energy Advisor",
    certifications: "M. Eng., B. Tech., R. EA (ERS, MURB)",
    saitInfo: "",
    // bio: "Brian brings extensive customer service experience and previous work as an electrician to his work with 4 Elements. His attention to detail is of exceptional value as a field Energy Advisor in Training,    and he is responsible for the majority of 4 Elements’ on-site testing and reviews. Brian is currently    under mentorship with senior staff as he works towards Energy Advisor registration.",
    linkedinLink: "",
    isDirector: false,
  },
  {
    index: 7,
    name: "Ruchi Patel",
    photoURL: "/images/team/RP.png",
    occupations: "Administrative Assistant",
    certifications: "MSc Mathematics, BSc",
    saitInfo: "",
    // bio: "Brian brings extensive customer service experience and previous work as an electrician to his work with 4 Elements. His attention to detail is of exceptional value as a field Energy Advisor in Training,    and he is responsible for the majority of 4 Elements’ on-site testing and reviews. Brian is currently    under mentorship with senior staff as he works towards Energy Advisor registration.",
    linkedinLink: "",
    isDirector: false,
  },
  {
    index: 8,
    name: "Hayley Puppato",
    photoURL: "/images/team/HP2.png",
    occupations: "Sr. Energy Advisor",
    certifications: "BSc, R.EA (ERS, MURB, ESNH, NZE, QAS)",
    saitInfo: "Environmental Technology",
    // bio: "With an educational background in environmental sciences, Hayley has been working with high-performance homes and green building research since 2019. While Hayley’s primary role at 4 Elements is providing modeling and labels for single-family residential homes, she is qualified as a    Multi-Unit Residential Building (MURB) Energy Advisor and is currently under mentorship to become    a Net Zero Energy Advisor. Hayley supports the team on larger research and testing projects    including Net Zero investigations and large building testing.",
    linkedinLink: "",
    isDirector: false,
  },
];

export default teamItems;
