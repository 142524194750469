import React from "react";

import {
  Box,
  Grid,
  ImageList,
  ImageListItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MainContent from "../../components/mainContent-Component";

import program01 from "../../assets/img/programs/BW/01_01_netzerohome.png";
import program02 from "../../assets/img/programs/BW/01_02_netzerohome.png";
import program03 from "../../assets/img/programs/BW/02_leed.png";
import program04 from "../../assets/img/programs/BW/03_leedcanada.png";
import program05 from "../../assets/img/programs/BW/04_energuide.png";
import program06 from "../../assets/img/programs/BW/05_builtgreen.png";
import program07 from "../../assets/img/programs/BW/06_energystar.png";
import program08 from "../../assets/img/programs/BW/07_r2000.png";
import { TitleBiColor } from "../../components/titles";
import { Link } from "react-router-dom";

const imageList = [
  [program01, "https://www.chba.ca/CHBA/BuyingNew/Net-Zero-Homes.aspx/"],
  [program02, "https://www.chba.ca/CHBA/BuyingNew/Net-Zero-Homes.aspx/"],
  // [program03, "https://www.cagbc.org/our-work/certification/leed/homes/"],
  // [program04, "https://www.cagbc.org/our-work/certification/leed/homes/"],
  [program05, "https://www.nrcan.gc.ca/energy/efficiency/homes/20578"],
  [program06, "http://www.builtgreencanada.ca/"],
  [program07, "https://www.nrcan.gc.ca/energy/efficiency/housing/new-homes/5057"],
  [program08, "https://www.nrcan.gc.ca/energy/efficiency/homes/20575"],
];

const CompanySystems = () => {
  //   const breakpoint = useBreakpoint();
  const theme = useTheme();
  // var breakPoints = [];
  var breakPoints = [];
  breakPoints["xs"] = [useMediaQuery(theme.breakpoints.only("xs")), 2, 40];
  breakPoints["sm"] = [useMediaQuery(theme.breakpoints.only("sm")), 3, 60];
  breakPoints["md"] = [useMediaQuery(theme.breakpoints.only("md")), 3, 60];
  breakPoints["lg"] = [useMediaQuery(theme.breakpoints.only("lg")), 3, 60];
  breakPoints["xl"] = [useMediaQuery(theme.breakpoints.only("xl")), 3, 60];

  const valid = Object.keys(breakPoints).filter((key) => {
    if (breakPoints[key][0]) {
      return breakPoints[key];
    }
    return null;
  });

  const col = breakPoints[valid[0]][1];
  const gap = breakPoints[valid[0]][2];

  return (
    <>
      <MainContent backgroundColor="#EEF8EE">
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
          display={"flex"}
          spacing={4}
        >
          <Grid item xs={5} sm={8} md={8} order={{ xs: 1, sm: 1, md: 0 }}>
            {breakPoints.map((val) => console.log(val))}
            <ImageList cols={col} gap={gap} sx={{ paddingBottom: "3rem" }}>
              {imageList.map((imageItem) => {
                return (
                  <Link target="_blank" to={imageItem[1]}>
                    <ImageListItem
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    >
                      <img src={imageItem[0]} alt="support" style={{width:"70%"}}/>
                    </ImageListItem>
                  </Link>
                );
              })}
            </ImageList>
          </Grid>
          <Grid item xs={3} sm={8} md={4} order={{ xs: 0, sm: 0, md: 1 }}>
            <Box
              marginTop={"2rem"}
              // marginBottom={"2rem"}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <Box textAlign={"end"}>
                <TitleBiColor
                  revert
                  hSize="h3"
                  part2={" We work with a large variety of "}
                  part1={"rating systems."}
                  upperCase={false}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </MainContent>
    </>
  );
};

export default CompanySystems;
