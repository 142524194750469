import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Box } from "@mui/material";

import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme(
  {
    typography:{
      fontFamily:"Arial, Cambria"
    },
    palette:{
      primary:{
        "50":"#111",
        main: "#FFF",
        light:"#EEE",
        contrastText:"#0B7B04",
      },
      secondary:{
        main: "#0B7B04",
        contrastText:"#9A9A9A",
        dark:"#232724"
      },
      text:{
        primary:"#747572",
        secondary:"#EBECEB",
      },
    }
  }
);
theme = responsiveFontSizes(theme);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
