import integratedDesign from "../../../assets/img/services/Residential/R01_integrated-design.JPG";
import consultation from "../../../assets/img/services/Residential/R02_consultation.JPG";
// import energyModeling from "../../../assets/img/services/Residential/R03_energy-modeling3.jpg";
import energyModeling0 from "../../../assets/img/services/Residential/R03_energy-modeling.jpg";
// import verification from "../../../assets/img/services/Residential/R04_verification.jpg";
import verification2 from "../../../assets/img/services/Residential/R04_verification2.jpg";
// import warranty from "../../../assets/img/services/Residential/R05_warranty.JPG";
import warranty2 from "../../../assets/img/services/Residential/R05_warranty2.JPG";
import education from "../../../assets/img/services/Residential/R06_education.jpg";

const residentialItems = [
  {
    title: "Energy Modeling",
    introduction:
      "4 Elements’ experienced team of NRCan licensed Energy Advisors provides energy modeling and compliance reporting for use in permit submission.",
    providerList: [
      "Benchmark energy model simulation",
      "Benchmark reporting and 9.36 code compliance letter for BP submission",
      "Complete RSI value calculations based on specifications",
      "Standard details for air tightness",
    ],
    weProvide:false,
    urlIndex: 2,
    // image: energyModeling0,
  },
  {
    title: "Consulting",
    introduction:
      "4 Elements’ multi-disciplined team of accredited professionals provides our clients with accurate information and insightful analysis to support them in building for improved energy efficiency, sustainability, and resilience. We can optimize your specification for cost effective performance, and help you take your project from code minimum to Net Zero.",
    providerList: [
      "Integrated design process (IDP) and IDP facilitation",
      "Performance analysis and optimization",
      "System recommendation",
      "Heat loss heat gain calculations (CSA F280 room by room)",
      "Ventilation design (CSA F326 calculations)",
      "Mid-construction reviews and final testing",
    ],
    weProvide:false,
    urlIndex: 1,
    // image: consultation,
  },
  {
    title: "Verification & Testing",
    introduction:
      "4 Elements provides trusted third-party testing and verification for most current high performance environmental and sustainable home certification programs in Canada.",
    providerList: [
      "Mid-construction reviews",
      "Air tightness testing",
      "Built Green verification",
      "CHBA NZE verification and submission",
      "CMHC Affordable Home Fund application stage and final reporting",
    ],
    weProvide:false,
    urlIndex: 3,
    // image: verification2,
  },
  {
    title: "Education",
    introduction:
      "4 Elements offers training opportunities for industry and trades, including client, site, and sales staff, to promote better understanding of energy efficient construction, the processes involved, and the benefits of its outcomes.",
    providerList: [
      "On-site training for site staff and trades with detailed site walkthrough",
      "In office training for management and sales staff",
    ],
    weProvide:false,
    urlIndex: 5,
    // image: education,
  },
  {
    title: "Building Diagnostics",
    introduction:
      "4 Elements offers a combination of IR Thermography and blower door testing that can determine the cause and extent of warranty-related issues while minimizing the need for more costly and invasive investigation. This allows for quick identification of potential solutions and the development of remediation plans before any re-work or demolition is started.",
    providerList: [
      "IR Thermal Scanning",
      "Air tightness testing",
    ],
    weProvide:true,
    urlIndex: 4,
    // image: warranty2,
  },
  
];

export default residentialItems;