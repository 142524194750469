import {
  Avatar,
  Box,
  Divider,
  Grid,
  Typography,
  duration,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainContent from "../../components/mainContent-Component";
import { PageTitle } from "../../components/titles";

// import item from "./data/teamItems";
import teamItems from "./data/teamItems";
import Footer from "../../components/footer";
import directorsItems from "./data/directorsItems";

import saitLogo from "../../assets/img/sait_logo.jpg";
import naitLogo from "../../assets/img/nait_logo.png";
import { Animate, AnimateGroup } from "react-simple-animate";
import setHeight from "../../utils/setHeight";

import OurFacts from "../landing/OurFacts";
import MapFacts from "../landing/MapFacts";

const Team = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    setHeight();
  }, []);

  const propsMain = {
    start: { opacity: 0 },
    end: { opacity: 1 },
    duration: 0.2,
  };

  const propsTeam = {
    start: { opacity: 0 },
    end: { opacity: 1 },
    duration: 0.15,
  };

  const [animateTeam, setAnimateTeam] = useState(false);

  return (
    <>
      <Animate
        play
        {...propsMain}
        onComplete={() => {
          setAnimateTeam(true);
        }}
      >
        <Box
          className="callResize"
          display={"flex"}
          flexDirection={"column"}
          // height={`calc(100vh - 72px)`}
          justifyContent={"space-between"}
        >
          <MainContent
            paddingX={{ xs: "2rem", sm: "4rem", md: "2rem", lg: "1rem" }}
          >
            <PageTitle>Team</PageTitle>

            <Grid container display={"flex"} md={12} lg={12} spacing={0}>
              <AnimateGroup play={animateTeam}>
                {teamItems.map((item, index) => {
                  return (
                    <Grid
                      md={item.isDirector ? 12 : 6}
                      lg={item.isDirector ? 12 : 6}
                      // md={12}
                      // lg={12}
                      margin={"1rem 0 2rem 0"}

                      sm={12}
                      width={"100%"}
                      // padding={"1rem"}
                    >
                      <Animate {...propsTeam} sequenceIndex={index}>
                        <Box
                          display={"flex"}
                          flexDirection={{
                            xs: "column",
                            sm: "column",
                            md: "row",
                            lg: "row",
                          }}
                        >
                          <Box
                            display={"flex"}
                            justifyContent={{ xs: "center", sm: "flex-start", md:"flex-start" }}
                            // alignSelf={{ xs: "center", sm: "flex-start", md:"flex-start" }}
                            // width={"100%"}
                            //  margin={{ xs: "auto", sm: "left" }}
                          >
                            <Avatar
                              src={item.photoURL}
                              sx={{
                                width: 200,
                                height: 200,
                                margin: "0 2rem 1rem 2rem",
                              }}
                            />
                          </Box>
                          <Box
                            width={"100%"}
                            display={"flex"}
                            flexDirection={"column"}
                          >
                            <Typography color={"secondary"} variant="h4">
                              {item.name}
                            </Typography>
                            <Divider sx={{ margin: "0.5rem 0rem" }} />
                            <Typography
                              color={"secondary"}
                              variant="p"
                              marginBottom={2}
                            >
                              {item.occupations}
                            </Typography>
                            <Typography color={"text.primary"} variant="p">
                              {item.certifications}
                            </Typography>
                            {item.saitInfo == "" ? (
                              ""
                            ) : (
                              <>
                                <Box display={"flex"} flexDirection={"row"}>
                                  <img
                                    alt="sait"
                                    src={
                                      item.saitInfo.includes("NAIT")
                                        ? naitLogo
                                        : saitLogo
                                    }
                                    height={"20px"}
                                  />
                                  <Typography color={"text.primary"}>
                                    &ensp;{item.saitInfo}
                                  </Typography>
                                </Box>
                              </>
                            )}
                            <Typography
                              lineHeight={"1.4rem"}
                              color={"text.primary"}
                              variant="p"
                              textAlign={"justify"}
                              marginTop={"1rem"}
                            >
                              {item.bio}
                            </Typography>
                          </Box>
                        </Box>
                      </Animate>
                    </Grid>
                  );
                })}
              </AnimateGroup>

              {/* ------------------------------------------- */}
              <PageTitle>Directors</PageTitle>
              <AnimateGroup play={animateTeam}>
                {directorsItems.map((item, index) => {
                  return (
                    <Grid
                      // md={item.isDirector ? 12 : 6}
                      // lg={item.isDirector ? 12 : 6}
                      md={6}
                      // md={12}
                      // lg={12}
                      // margin={"16px 0 32px 0"}
                      marginBottom={"1rem"}

                      sm={12}
                      width={"100%"}
                      // padding={"1rem"}
                    >
                      <Animate {...propsTeam} sequenceIndex={index}>
                        <Box
                          display={"flex"}
                          flexDirection={{
                            xs: "column",
                            sm: "column",
                            md: "row",
                            lg: "row",
                          }}
                        >
                          {/* <Box
                            display={"flex"}
                            justifyContent={{ xs: "center", sm: "flex-start", md:"flex-start" }}
                          >
                            <Avatar
                              src={item.photoURL}
                              sx={{
                                width: 200,
                                height: 200,
                                margin: "0 2rem 1rem 2rem",
                              }}
                            />
                          </Box> */}
                          <Box
                            width={"100%"}
                            display={"flex"}
                            // paddingLeft={"1rem"}
                            // paddingRight={"4rem"}
                            
                            flexDirection={"column"}
                          >
                            <Typography color={"secondary"} variant="h4">
                              {item.name}
                            </Typography>
                            <Divider sx={{ margin: "0.5rem 0.5rem" }} />
                            <Typography
                              color={"secondary"}
                              variant="p"
                              marginBottom={2}
                            >
                              {item.occupations}
                            </Typography>
                            <Typography color={"text.primary"} variant="p">
                              {item.certifications}
                            </Typography>
                            {item.saitInfo == "" ? (
                              ""
                            ) : (
                              <>
                                <Box display={"flex"} flexDirection={"row"}>
                                  <img
                                    alt="sait"
                                    src={
                                      item.saitInfo.includes("NAIT")
                                        ? naitLogo
                                        : saitLogo
                                    }
                                    height={"20px"}
                                  />
                                  <Typography color={"text.primary"}>
                                    &ensp;{item.saitInfo}
                                  </Typography>
                                </Box>
                              </>
                            )}
                            <Typography
                              lineHeight={"1.4rem"}
                              color={"text.primary"}
                              variant="p"
                              textAlign={"justify"}
                              marginTop={"1rem"}
                            >
                              {item.bio}
                            </Typography>
                          </Box>
                        </Box>
                      </Animate>
                    </Grid>
                  );
                })}
              </AnimateGroup>
            </Grid>
          </MainContent>
          <OurFacts />
          <Grid>
          <MapFacts />
          </Grid>
          <Footer />
        </Box>
      </Animate>
    </>
  );
};

export default Team;
