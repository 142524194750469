import {
  Box,
  Grid,
  ImageList,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import CountUp from "react-countup";
import MainContent from "../../components/mainContent-Component";
import factNumbers from "../landing/data/factNumbers";

const OurFacts = () => {
  const theme = useTheme();
  var breakPoints = [];
  breakPoints["xs"] = [useMediaQuery(theme.breakpoints.only("xs")), 1, 40];
  breakPoints["sm"] = [useMediaQuery(theme.breakpoints.only("sm")), 2, 60];
  breakPoints["md"] = [useMediaQuery(theme.breakpoints.only("md")), 3, 60];
  breakPoints["lg"] = [useMediaQuery(theme.breakpoints.only("lg")), 5, 60];
  breakPoints["xl"] = [useMediaQuery(theme.breakpoints.only("xl")), 5, 60];

  const valid = Object.keys(breakPoints).filter((key) => {
    if (breakPoints[key][0]) {
      return breakPoints[key];
    }

    return false;
  });

  const col = breakPoints[valid[0]][1];
  // const gap = breakPoints[valid[0]][2];

  return (
    <>
      <MainContent backgroundColor="#2F3430">
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          display={"flex"}
          margin={"auto"}
          spacing={4}
        >
          <Grid
            sx={{ color: "white" }}
            xs={12}
            sm={12}
            md={8}
            lg={3}
            display={"flex"}
            justifyContent={{ sm: "center", xs: "center" }}
            margin={{ md: "auto" }}
          >
            <Typography
              variant="h2"
              textTransform={"uppercase"}
              fontFamily={"Oswald"}
              // fontSize={"2rem"}
              justifyContent={{ sm: "center", xs: "center" }}
            >
              4 Elements Projects
            </Typography>
          </Grid>
          <Grid sx={{ color: "white" }} xs={12} sm={12} md={12} lg={9}>
            <ImageList cols={col} sx={{ marginRight: "2rem" }}>
              {factNumbers.map((item) => {
                return (
                  <Box
                    display={"flex"}
                    // justifyContent={"center"}
                    // alignContent={"center"}
                    key={item.legend}
                    flexDirection={"column"}
                    textAlign={"center"}
                  >
                    <Typography
                      fontSize={"3.5rem"}
                      color={"text.secondary"}
                      fontWeight={"bold"}
                    >
                      <CountUp start={1} end={item.numbers} redraw={true} duration={3}/>
                    </Typography>
                    <Typography  variant="h6" color={"#9a9a9a"} fontFamily={"Oswald"}>
                      {item.legend}
                    </Typography>
                  </Box>
                );
              })}
            </ImageList>
          </Grid>
        </Grid>
      </MainContent>
    </>
  );
};

export default OurFacts;
