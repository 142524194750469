import React from "react";

import {
  Box,
  Grid,
  ImageList,
  ImageListItem,
  useMediaQuery,
  Typography,
  useTheme,
} from "@mui/material";
import MainContent from "../../components/mainContent-Component";

// import sup01 from "../../assets/img/membership/BW/01_usgbc.png";
// import sup02 from "../../assets/img/membership/BW/02_cagbc.png";
// import sup03 from "../../assets/img/membership/BW/03_leedcanada2.png";
import sup04 from "../../assets/img/membership/BW/04_bild.png";
import sup05 from "../../assets/img/membership/BW/05_dot-eco-logo.png";
import sup06 from "../../assets/img/membership/BW/06_BULLFROG-logo.png";
import sup07 from "../../assets/img/membership/BW/07_SSRIA.png";
// import sup08 from "../../assets/img/membership/BW/08_AEE.png";
import sup09 from "../../assets/img/membership/BW/09_netzero.png";
// import sup10 from "../../assets/img/membership/BW/10_BCxA3.png";
import sup11 from "../../assets/img/membership/BW/11_cacea.png";
// import sup12 from "../../assets/img/membership/BW/12_hrai-logo.png";
import { TitleBiColor } from "../../components/titles";
import { Link } from "react-router-dom";

const imageList = [
  // [sup01, "https://new.usgbc.org/"],
  // [sup02, "https://www.cagbc.org/"],
  // [
  //   sup03,
  //   "https://www.cagbc.org/our-work/certification/leed/homes/",
  // ],
  [sup04, "https://bildcr.com/"],
  [sup05, "https://go.eco/"],
  [sup06, "https://www.bullfrogpower.com/"],
  [sup07, "https://www.ssria.ca/"],
  // [sup08, "https://www.aeecenter.org/"],
  [
    sup09,
    "https://www.chba.ca/CHBAFR/CHBA/Housing_in_Canada/Net_Zero_Energy_Program/NZE_Qualified_Service_Organizations.aspx",
  ],
  // [sup10, "https://www.bcxa.org/"],
  [sup11, "https://cacea.ca/"],
  // [sup12, "https://www.hrai.ca/"],
];

const CompanySupports = () => {
  //   const breakpoint = useBreakpoint();
  const theme = useTheme();
  // var breakPoints = [];
  var breakPoints = [];
  breakPoints["xs"] = [useMediaQuery(theme.breakpoints.only("xs")), 2, 90];
  breakPoints["sm"] = [useMediaQuery(theme.breakpoints.only("sm")), 3, 40];
  breakPoints["md"] = [useMediaQuery(theme.breakpoints.only("md")), 3, 60];
  breakPoints["lg"] = [useMediaQuery(theme.breakpoints.only("lg")), 3, 60];
  breakPoints["xl"] = [useMediaQuery(theme.breakpoints.only("xl")), 3, 60];

  const valid = Object.keys(breakPoints).filter((key) => {
    if (breakPoints[key][0]) {
      return breakPoints[key];
    }
    return false;
  });

  const col = breakPoints[valid[0]][1];
  const gap = breakPoints[valid[0]][2];

  return (
    <>
      <MainContent backgroundColor="#DBE9DE">
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
          display={"flex"}
          spacing={4}
        >
          <Grid item xs={3} sm={8} md={4}>
            <Box
              marginTop={"2rem"}
              // height={"100%"}
              // marginBottom={"2rem"}
              // sx={{
              //   display: "flex",
              //   justifyContent: "center",
              //   alignItems: "center",

              // }}
              display={"flex"}
              width={"100%"}
              justifyContent={"center"}
              alignContent={"center"}

              // marginY={"auto"}
              // alignItems={"center"}
              // height={"100%"}

              // width={"100px"}
              // height={"100%"}
              // justifyContent={"center"}
              // justifyContent={"end"}
              // alignContent={"center"}
            >
              <TitleBiColor
                revert
                hSize="h3"
                part1={"proudly supports."}
                part2={"Our company "}
                upperCase={false}
              />
            </Box>
          </Grid>
          <Grid item xs={5} sm={8} md={8}>
            {breakPoints.map((val) => console.log(val))}
            <ImageList cols={col} gap={gap} sx={{ paddingBottom: "3rem" }}>
              {imageList.map((imageItem) => {
                return (
                  <Link target="_blank" to={imageItem[1]}>
                    <ImageListItem
                      sx={{
                        //   aspectRatio: "3/1",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        // margin:"24px",
                      }}
                    >
                      <img
                        src={imageItem[0]}
                        alt="support"
                        // style={{}}
                        style={{width:"60%"}}
                      />
                    </ImageListItem>
                  </Link>
                );
              })}
            </ImageList>
          </Grid>
        </Grid>
      </MainContent>
    </>
  );
};

export default CompanySupports;
