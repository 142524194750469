const directorsItems = [
    {
      index: 1,
      name: "Tyler Hermanson",
      photoURL: "/images/team/tyler2.png",
      occupations: "Owner",
      certifications: "",
      saitInfo: "",
    //   bio: 'Tyler is co-owner and Director of Innovation at 4 Elements. An architectural technologist by training and longtime Energy Advisor, he has over 15 years’ experience in energy efficient, sustainable homes and buildings. Tyler leads a diverse team of green building experts while undertaking more complex energy and verification/ certification projects as a Certified Professional Building Commissioner. Tyler is Primary Quality Assurance Designee as a LEED for Homes Provider. He was extensively involved in the early adoption and development of both Built Green High Density and Net Zero Energy Homes programs in Alberta and continues to support these programs through training. Tyler is a committed volunteer who gives his time to the NBC 2020 Working Group, BILD Calgary Region, and is a member of the Canadian Armed Forces Reserves with 33 Engineer Squadron.',
      linkedinLink: "https://www.linkedin.com/in/tyler-hermanson-2553b727",
      isDirector: false,
    },
    {
      index: 2,
      name: "Karen Goddard Hermanson",
      photoURL: "/images/team/karen2.png",
      occupations: "Owner",
      certifications:"",
        // "BFA, CEA, EMIT, LEED AP (Homes), LEED Homes Green Rater, R.EA (MURB, ESNH), Built Green HD Verifier, ITC Level 1 Thermographer",
        saitInfo: "",  
    //   saitInfo: "Moisture Control Technician",
      // bio: "Karen is co-owner and director of 4 Elements. She stepped into the role of Company Manager in 2018, after shifting away from a 20-year career in the performing arts sector. Education and training     opportunities have enabled her to meet the company goal of a broad range of qualified     professionals who provide sustainable building and energy efficiency consulting to builders across     Alberta and BC. Karen is a Certified Energy Auditor, Energy Manager (in Training), Certified Level 1     Thermographer and LEED Green Rater. She leads 4 Elements’ commercial air tightness testing and     verification services and recently led the development of air tightness training courses for builders     and trades. Karen will be working towards qualification as a Certified Air Barrier Specialist (ABAA) in     fall of 2023.",
      linkedinLink: "https://www.linkedin.com/in/karen-goddard-hermanson-9a055831",
      isDirector: false,
    },
];

export default directorsItems;