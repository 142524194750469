import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";

import pressReleases from "./data/pressReleases";
import MainContent from "../../components/mainContent-Component";
import { PageTitle } from "../../components/titles";
import Footer from "../../components/footer";
import { Animate } from "react-simple-animate";
import setHeight from "../../utils/setHeight";

const News = () => {
  const [listNews, setListNews] = useState([]);
  const [currentYear, setCurrentYear] = useState("All");
  const [years, setYears] = useState([]);

  const updateYearSelect = (e) => {
    const yCheck = Number(e.target.innerText);

    if (e.target.innerText === "All") {
      setCurrentYear("All");
    }
    if (yCheck) {
      setCurrentYear(yCheck);
    }
  };

  const updateYearSelectMB = (e) => {
    const yCheck = Number(e.target.value);

    if (e.target.value === "All") {
      setCurrentYear("All");
    }
    if (yCheck) {
      setCurrentYear(yCheck);
    }
  };

  const getYears = () => {
    setYears([]);

    let y = [];
    pressReleases.forEach((item) => {
      const convertedDate = new Date(item.date);
      const year = convertedDate.getFullYear();

      if (y.includes(year) === false) {
        y.push(year);
      }
    });
    setYears(y);
  };

  useEffect(() => {
    getYears();
  }, [setYears]);

  useEffect(() => {
    let list = [];
    pressReleases.forEach((item) => {
      const d = new Date(item.date);

      const it = {
        title: item.title,
        year: d.getFullYear(),
        date: new Date(item.date).toDateString().substr(3),
        media: item.media,
        link: item.link,
      };

      if (d.getFullYear() === currentYear) {
        list.push(it);
      } else if (currentYear === "All") {
        list.push(it);
      }

      if (!currentYear) {
        list.push(it);
      }
    });

    setListNews(list);
  }, [currentYear]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setHeight();
  }, []);

  const props = {
    start: { opacity: 0 },
    end: { opacity: 1 },
    duration: 0.15,
  };

  return (
    <>
      <Animate {...props} play>
        <Box
          className="callResize"
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          <MainContent
            id={"mediaMainContent"}
            paddingX={{ xs: "0rem", sm: "0rem", md: "2rem", lg: "1rem" }}
            // height="100vh"
          >
            <PageTitle>Press News</PageTitle>
            <Grid>
              <Box>
                <Box
                  display={{ lg: "none", sm: "flex", xs: "flex" }}
                  marginBottom={"1rem"}
                  paddingLeft={"2rem"}
                >
                  <FormControl sx={{ width: "40%" }}>
                    <Select
                      variant="standard"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={currentYear}
                      defaultValue={"All"}
                      onChange={(e) => {
                        //console.log(e.);
                        updateYearSelectMB(e);
                      }}
                    >
                      <MenuItem value={"All"}>
                        <Typography color={"secondary.main"}>All</Typography>
                      </MenuItem>
                      {years.map((year) => {
                        return (
                          <MenuItem value={year}>
                            <Typography color={"secondary.main"}>
                              {year}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                      {/* <MenuItem value={0}>All</MenuItem>
                  <MenuItem value={1}>2021</MenuItem>
                  <MenuItem value={2}>2020</MenuItem> */}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  display={{ lg: "flex", sm: "none", xs: "none" }}
                  flexDirection={"row"}
                  alignContent={"center"}
                  // justifyContent={"space-around"}
                >
                  <List
                    sx={{ display: "flex", width: "100%" }}
                    // sx={{
                    //   display: { sm: "flex", xs: "none" },
                    //   justifyContent: "flex-start",
                    // }}
                  >
                    <ListItemButton
                      onClick={(e) => {
                        updateYearSelect(e);
                      }}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography color={"secondary.main"}>All</Typography>
                    </ListItemButton>
                    {years.map((year) => {
                      return (
                        <>
                          <ListItemButton
                            onClick={(e) => {
                              updateYearSelect(e);
                            }}
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Typography color={"secondary.main"}>
                              {year}
                            </Typography>
                          </ListItemButton>
                        </>
                      );
                    })}
                  </List>
                </Box>
                <Divider />
                <List>
                  {listNews.map((news) => {
                    return (
                      <>
                        <ListItem>
                          <Box>
                            <CardContent>
                              <Typography
                                sx={{ fontSize: 14 }}
                                color="text.primary"
                                gutterBottom
                              >
                                {news.date}
                              </Typography>
                              <Link href={news.link} target="#">
                                <Typography
                                  variant="h6"
                                  color={"secondary.main"}
                                >
                                  {news.title}
                                </Typography>
                              </Link>
                              <Typography color="text.primary">
                                {news.media}
                              </Typography>
                            </CardContent>
                            <CardActions sx={{ paddingTop: "0px" }}>
                              <Button size="small" target="#" href={news.link}>
                                Lear More
                              </Button>
                            </CardActions>
                          </Box>
                        </ListItem>
                      </>
                    );
                  })}
                </List>
              </Box>
            </Grid>
          </MainContent>
          <Footer />
        </Box>
      </Animate>
    </>
  );
};

export default News;
