import React, { useState } from "react";
import {
  AppBar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  ThemeProvider,
  Toolbar,
  Typography,
  createTheme,
  responsiveFontSizes,
} from "@mui/material";
import MenuDrawer from "../menuDrawer";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import { Link } from "react-router-dom";
import elements4logo from "../../assets/img/4Elogo.png";

let themeHeader = createTheme({
  palette: {
    primary: {
      main: "#FFF",
      light: "#EEE",
      contrastText: "#0B7B04",
    },
    secondary: {
      main: "#0B7B04",
      contrastText: "#9A9A9A",
    },
    text: {
      primary: "#747572",
      secondary: "#EBECEB",
    },
  },
});

themeHeader = responsiveFontSizes(themeHeader);

const Header = () => {
  const [tabValue, setTabValue] = useState(0);
  const [anchorServices, setAnchorServices] = React.useState(null);
  const [anchorAbout, setAnchorAbout] = React.useState(null);
  const openServices = Boolean(anchorServices);
  const openAbout = Boolean(anchorAbout);

  const handleClose = () => {
    setAnchorServices(null);
    setAnchorAbout(null);
  };
  return (
    <React.Fragment>
      <ThemeProvider theme={themeHeader}>
        {/* <AppBar position="fixed" sx={{ maxWidth: "1140px", margin: "auto" }}> */}
        <AppBar
          position="fixed"
          sx={{ padding: "0px !important", width: "100%" }}
        >
          {/* <Toolbar sx={{ paddingX: "0px !important " }}> */}
          <Toolbar>
            <Box
              width={"1140px"}
              maxWidth={"1140px"}
              display={"flex"}
              flexDirection={"row"}
              margin={"auto"}
              alignItems={"center"}
              height={"72px"}
              justifyContent={"space-between"}
            >
              {/* <Box alignSelf={"center"}> */}
              <Box alignSelf={"center"}>
                {/* <Typography>4 Elements</Typography> */}
                <img
                  src={elements4logo}
                  alt="logo 4 elements black white"
                  height={"35rem"}
                />
              </Box>
              <Tabs
                sx={{ marginLeft: "auto", display: { xs: "none", md: "flex" } }}
                textColor="inherit"
                value={tabValue}
                indicatorColor="transparent"
                onChange={(e, value) => {
                  setTabValue(value);
                }}
              >
                <Tab label="Home" value={"/"} LinkComponent={Link} to={"/"} />
                <Tab
                  label="Services"
                  value={"/residential"}
                  LinkComponent={Link}
                  to={"/residential"}
                />
                <Tab
                  label="About"
                  value={"/team"}
                  LinkComponent={Link}
                  to={"/team"}
                />
                <Tab
                  label="Blog"
                  // value={"/contact"}
                  LinkComponent={Link}
                  href="https://4egreenhomes.medium.com/"
                  target="#"
                  to={"https://4egreenhomes.medium.com/"}
                />
                <Tab
                  label="Contact"
                  value={"/contact"}
                  LinkComponent={Link}
                  to={"/contact"}
                />
              </Tabs>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <MenuDrawer />
            </Box>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default Header;
