import { useMediaQuery, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
// import factNumbers from "../landing/data/factNumbers";
// import MainContent from "../../components/mainContent-Component";
import {
  MapContainer,
  TileLayer,
  useMapEvent,
  useMapEvents,
} from "react-leaflet";
import NumericMarker from "../../components/map/numericMarker";
import { locationsMain, locationsSpecific } from "./data/projectsLocation";

let map = null;

const MapFacts = () => {
  const theme = useTheme();
  var breakPoints = [];
  breakPoints["xs"] = [useMediaQuery(theme.breakpoints.only("xs")), 1, 40];
  breakPoints["sm"] = [useMediaQuery(theme.breakpoints.only("sm")), 2, 60];
  breakPoints["md"] = [useMediaQuery(theme.breakpoints.only("md")), 3, 60];
  breakPoints["lg"] = [useMediaQuery(theme.breakpoints.only("lg")), 5, 60];
  breakPoints["xl"] = [useMediaQuery(theme.breakpoints.only("xl")), 5, 60];

  // map reference
  const mapRef = useRef(null);
  const [zoom, setZoom] = useState(null);

  // use map events state
  const MapComponent = () => {
    map = useMapEvents({
      zoom: () => {
        // console.log("CLIC!");
        //console.log(map.getZoom());
        setZoom(map.getZoom());
        console.log(zoom);
        // return zoom;
      },
    });
    return null;
  };

  return (
    <>
      <MapContainer
        center={[50.264765, -101.337891]}
        maxZoom={13}
        minZoom={3}
        zoom={3.4}
        scrollWheelZoom={false}
        style={{ height: "400px", zIndex: 1 }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          // url="https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiamVpc29uIiwiYSI6ImNrazV6Z3l5YTE5NmoycW51eDl1ZTFnODkifQ.nkgu2FxtBBbzy3k4c_hwRQ"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {zoom < 4
          ? locationsMain.map((location) => {
              if (location[0] === "GrandTotal") return null;

              return (
                <>
                  <NumericMarker
                    number={location[1].toString()}
                    position={[location[2], location[3]]}
                  />
                </>
              );
            })
          : locationsSpecific.map((location) => {
              return (
                <>
                  <NumericMarker
                    number={location[2].toString()}
                    position={[location[4], location[5]]}
                  />
                </>
              );
            })}
        {/* {locationsMain.map((location) => {
          if (location[0] === "GrandTotal") return null;

          return (
            <>
              <NumericMarker
                number={location[1].toString()}
                position={[location[2], location[3]]}
              />
            </>
          );
        })} */}
        <MapComponent />
      </MapContainer>

      {/* </div> */}
    </>
  );
};

export default MapFacts;
