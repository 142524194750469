import { RouterProvider } from "react-router-dom";
import "./App.css";

import routes from "./routes/routes";
import setHeight from "./utils/setHeight";


function App() {

  window.addEventListener("resize",setHeight);

  return (
    <>
      <RouterProvider router={routes} />
      {/* <ScrollUpButton/> */}
    </>
  );
}

export default App;
